import { Center, Flex, Image, Stack, Text, Title } from "@mantine/core";
import NotFoundImage from '../../images/404.png';

/**
 * implementation of 404 page
 * @returns JSX
 */
export default function PageGeneralNotFound() {
    return (
        <Flex fluid p="md" style={{ height: "100vh", width: "100vw", alignContent: "center", alignItems: "center" }}>
            <Stack>
                <Center><Title>Seite nicht gefunden.</Title></Center>
                <Center>
                    <Text align="center" color="dimmed" size="lg">
                        Die aufgerufene Seite existiert nicht. Bitte prüfen Sie die aufgerufene URL,
                        möglicherweise ist diese nicht korrekt, oder die Seite wurde verschoben.
                        Sollte dies ein Fehler sein, bitte kontaktieren Sie unseren Support.
                    </Text>
                </Center>
                <Image p="xl" src={NotFoundImage} />
            </Stack>
        </Flex>
    )
}