import { ROUTE_BACKEND_GAMES_EDIT, ROUTE_BACKEND_GAMES_NEW, ROUTE_GAME } from "../../helpers/Routes";
import { Game } from "../../models";
import { Accordion, Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { Filter, Plus, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import { getFilterPredicates } from "../../helpers/Predicates";
import { PREDICATE_CONTAINS } from "../../helpers/Constants";
import SortFieldInput from "../../components/SortFieldInput";
import SortDirectionInput from "../../components/SortDirectionInput";
import List from "../../components/List";
import { useNavigate } from "react-router-dom";
import { deleteGame } from "../../helpers/Datastore";

/**
 * page to administer games
 * @returns JSX
 */
export default function PageBackendGames() {

    // globals
    const navigate = useNavigate();

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            sortField: "name",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
        ]);
    }

    return (
        <Stack>
            <Group position="apart">
                <Title>Spiele</Title>
                <Button
                    color="green"
                    leftIcon={<Plus />}
                    onClick={() => navigate(ROUTE_BACKEND_GAMES_NEW)}
                >
                    Neues Spiel erstellen
                </Button>
            </Group>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    {...form.getInputProps('name')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'name', label: 'Name' },
                                        { value: 'createdAt', label: 'Erstellzeit' },
                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group position='apart'>
                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={Game}
                id="id"
                dataStructure={[
                    { key: "name", title: "Name" },
                    { key: "backgroundColor", title: "Hintergrundfarbe", type: "color" },
                    { key: "cardImages", title: "Anzahl Bilder", type: "count" },
                    { key: "createdAt", title: "erstellt am", type: "timestamp" },
                    { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
                ]}
                showRoute={ROUTE_GAME}
                showExternal={true}
                editRoute={ROUTE_BACKEND_GAMES_EDIT}
                delete={true}
                deleteCallback={deleteGame}
                filter={getFilter()}
                sort={s => s[form.values.sortField](form.values.sortDirection)}
            />
        </Stack>
    );
}