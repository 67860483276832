import * as Yup from 'yup';
import { OPTIONS_YESNO, OPTIONS_YESNO_WITHALL, USERGROUPS, USERGROUPS_WITHALL } from './Constants';

// validation error messages
export const VALIDATION_ERROR_EMAIL = "E-Mail-Adresse ungültig.";
export const VALIDATION_ERROR_PASSWORD_REQUIRED = "Geben Sie Ihr persönliches Passwort ein.";
export const VALIDATION_ERROR_PASSWORD_REQUIREMENTS = "Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Zahl enthalten.";
export const VALIDATION_ERROR_PASSWORD_CONFIRMATION_REQUIRED = "Geben Sie das Passwort zur Bestätigung erneut ein.";
export const VALIDATION_ERROR_PASSWORD_CONFIRMATION_INVALID = "Passwörter stimmen nicht überein.";
export const VALIDATION_ERROR_2FA_CODE = "Geben Sie den erhaltenen Bestätigungscode ein.";
export const VALIDATION_ERROR_GAME_NAME = "Vergeben Sie einen Namen für das Spiel.";
export const VALIDATION_ERROR_GAME_CARDIMAGES = "Bitte wählen Sie die Bilder für die Vorderseite der Karten aus."
export const VALIDATION_ERROR_SCORE_NAME = "Bitte geben Sie Ihren Namen ein.";
export const VALIDATION_ERROR_USERGROUP = "Wählen Sie eine Berechtigungsgruppe.";
export const VALIDATION_ERROR_OPTIONS_YESNO = "Wählen Sie eine Option.";

// templates for form validation
export const VALIDATION_SCHEMA_EMAIL = Yup.string().required(VALIDATION_ERROR_EMAIL).email(VALIDATION_ERROR_EMAIL);
export const VALIDATION_SCHEMA_PASSWORD = Yup.string().required(VALIDATION_ERROR_PASSWORD_REQUIRED).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, VALIDATION_ERROR_PASSWORD_REQUIREMENTS);
export const VALIDATION_SCHEMA_PASSWORT_REQUIRED = Yup.string().required(VALIDATION_ERROR_PASSWORD_REQUIRED);
export const VALIDATION_SCHEMA_PASSWORD_CONFIRMATION = Yup.string().required(VALIDATION_ERROR_PASSWORD_CONFIRMATION_REQUIRED).oneOf([Yup.ref('password'), null], VALIDATION_ERROR_PASSWORD_CONFIRMATION_INVALID);
export const VALIDATION_SCHEMA_2FA_CODE = Yup.string().required(VALIDATION_ERROR_2FA_CODE);
export const VALIDATION_SCHEMA_GAME_NAME = Yup.string().required(VALIDATION_ERROR_GAME_NAME);
export const VALIDATION_SCHEMA_GAME_CARDIMAGES = Yup.array(VALIDATION_ERROR_GAME_CARDIMAGES).of(Yup.string(VALIDATION_ERROR_GAME_CARDIMAGES).required(VALIDATION_ERROR_GAME_CARDIMAGES)).required(VALIDATION_ERROR_GAME_CARDIMAGES);
export const VALIDATION_SCHEMA_SCORE_NAME = Yup.string().required(VALIDATION_ERROR_SCORE_NAME);
export const VALIDATION_SCHEMA_USERGROUP = Yup.mixed().oneOf(USERGROUPS, VALIDATION_ERROR_USERGROUP).required(VALIDATION_ERROR_USERGROUP);
export const VALIDATION_SCHEMA_USERGROUP_WITHALL = Yup.mixed().oneOf(USERGROUPS_WITHALL, VALIDATION_ERROR_USERGROUP).required(VALIDATION_ERROR_USERGROUP);
export const VALIDATION_SCHEMA_OPTIONS_YESNO = Yup.mixed().oneOf(OPTIONS_YESNO, VALIDATION_ERROR_OPTIONS_YESNO).required(VALIDATION_ERROR_OPTIONS_YESNO);
export const VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL = Yup.mixed().oneOf(OPTIONS_YESNO_WITHALL, VALIDATION_ERROR_OPTIONS_YESNO).required(VALIDATION_ERROR_OPTIONS_YESNO);
