import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container, Group } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth, DataStore } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORT_REQUIRED } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import { BC_TYPE_USERCHANGE } from "../../helpers/Constants";
import { useLocation } from "react-router-dom";
import { useUserState } from "../../helpers/GlobalUserState";
import { useEffect } from "react";
import { clearDataStore } from "../../helpers/Datastore";
import Broadcaster from "../../components/Broadcaster";
import * as Yup from 'yup';
import { ROUTE_BACKEND, ROUTE_BACKEND_PASSWORD_FORGOT, ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD, ROUTE_BACKEND_SIGNUP_CONFIRM } from "../../helpers/Routes";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    password: VALIDATION_SCHEMA_PASSWORT_REQUIRED,
});

export default function PageBackendSignIn() {

    // globals
    const navigate = useNavigate();
    const location = useLocation();
    const user = useUserState();

    // get email if provided
    const { email } = useParams();

    /** 
     * use effect hook 
     */
    useEffect(() => {
        if (user.id) {
            redirectAfterLogin()
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * submit callback of login form
     * @param {object} values form values
     */
    const submitCallback = async (values) => {
        try {
            Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: "new" });
            await clearDataStore();
            const loginResult = await Auth.signIn(values.email.toLowerCase(), values.password);
            await DataStore.start();

            if (loginResult.challengeName === "NEW_PASSWORD_REQUIRED") {
                showNotification({ message: "Sie müssen Ihr Passwort ändern.", color: 'green', icon: <Check /> });
                navigate(`${ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD}/${values.email.toLowerCase()}`);
            }
            else {
                showNotification({ message: "Sie haben sich erfolgreich angemeldet.", color: 'green', icon: <Check /> });
                redirectAfterLogin();
            }
        }
        catch (e) {
            if (e.code === "NotAuthorizedException" || e.code === "UserNotFoundException") {
                showNotification({ message: "E-Mail-Adresse oder Passwort falsch, oder es existiert kein Benutzerkonto mit der angegebenen E-Mail-Adresse.", color: 'red', icon: <X /> });
            }
            else if (e.code === "UserNotConfirmedException") {
                showNotification({ message: "Sie müssen Ihre E-Mail-Adresse erst bestätigen. Bitte führen Sie dies vor Anmeldung durch.", color: 'red', icon: <X /> });
                navigate(`${ROUTE_BACKEND_SIGNUP_CONFIRM}/${values.email.toLowerCase()}`);
            }
            else {
                throw e;
            }
        }
    }

    /**
     * wrapper to redirect to next page after login
     */
    const redirectAfterLogin = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        }
        else {
            navigate(ROUTE_BACKEND);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: email ? email : "",
            password: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100%" }}>
            <Container size={600} style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">Anmelden</Title>
                        <Stack>
                            <TextInput
                                withAsterisk
                                label="E-Mail-Adresse"
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Passwort"
                                placeholder="Passwort"
                                {...form.getInputProps('password')}
                            />
                        </Stack>
                        <Group position="right" mt={5}>
                            <Link to={`${ROUTE_BACKEND_PASSWORD_FORGOT}/${form.values.email.toLowerCase()}`}>
                                <Text className="pointer" underline color="dimmed" size="sm" align="center">
                                    Passwort vergessen? Passwort zurücksetzen
                                </Text>
                            </Link>
                        </Group>
                        <Button type="submit" fullWidth mt="xl">Anmelden</Button>
                    </Paper>
                </form>
            </Container>
        </Center>
    );

}