import { Burger, Divider, Group, MantineProvider, ScrollArea, Text, ThemeIcon, UnstyledButton, useMantineTheme } from '@mantine/core';
import { AppShell, Navbar, Header } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Check, DeviceGamepad, Graph, Home, Login, Logout, Users } from 'tabler-icons-react';
import { ROUTE_BACKEND, ROUTE_BACKEND_GAMES, ROUTE_BACKEND_GAMES_EDIT, ROUTE_BACKEND_GAMES_NEW, ROUTE_BACKEND_HOME, ROUTE_BACKEND_PASSWORD_FORGOT, ROUTE_BACKEND_PASSWORD_FORGOT_CONFIRM, ROUTE_BACKEND_SCORES, ROUTE_BACKEND_SIGNIN, ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD, ROUTE_BACKEND_USERS, ROUTE_CATCH, ROUTE_GAME, ROUTE_NOTFOUND, ROUTE_ROOT } from '../helpers/Routes';
import PageBackendGames from '../pages/backend/PageBackendGames';
import PageBackendHome from '../pages/backend/PageBackendHome';
import PageBackendUsers from '../pages/backend/PageBackendUsers';
import PageGame from '../pages/game/PageGame';
import PageGeneralNotFound from '../pages/general/PageGeneralNotFound';
import PageBackendSignIn from '../pages/backend/PageBackendSignIn';
import RouteBackendAdmin from './RouteBackendAdmin';
import RouteBackendUser from './RouteBackendUser';
import PageBackendResetPassword from '../pages/backend/PageBackendResetPassword';
import PageBackendResetPasswordConfirm from '../pages/backend/PageBackendResetPasswordConfirm';
import ScrollToTop from './ScrollToTop';
import ErrorOverlay from './ErrorOverlay';
import LoadingOverlay from './LoadingOverlay';
import { Notifications, showNotification } from '@mantine/notifications';
import PageBackendChangePassword from '../pages/backend/PageBackendChangePassword';
import { Auth, Hub } from 'aws-amplify';
import { USER_RESET, USER_SET, useUserDispatch, useUserState } from '../helpers/GlobalUserState';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState';
import { BC_TYPE_USERCHANGE, USERGROUP_ADMIN, USERGROUP_USER } from '../helpers/Constants';
import Broadcaster from './Broadcaster';
import { clearDataStore } from '../helpers/Datastore';
import PageBackendGame from '../pages/backend/PageBackendGame';
import { useMediaQuery } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import PageBackendScores from '../pages/backend/PageBackendScores';
import MyHeimTierLandBanner from '../images/myheimtierland_banner.png';

// routes
const routes = (
    <Routes>
        <Route exact path={ROUTE_ROOT} element={<Navigate to={ROUTE_GAME} />} />
        <Route exact path={ROUTE_GAME} element={<PageGame />} />
        <Route exact path={`${ROUTE_GAME}/:id`} element={<PageGame />} />

        <Route exact path={ROUTE_BACKEND} element={<Navigate to={ROUTE_BACKEND_HOME} />} />
        <Route exact path={ROUTE_BACKEND_HOME} element={<RouteBackendUser Component={PageBackendHome} />} />
        <Route exact path={ROUTE_BACKEND_GAMES} element={<RouteBackendUser Component={PageBackendGames} />} />
        <Route exact path={ROUTE_BACKEND_GAMES_NEW} element={<RouteBackendUser Component={PageBackendGame} />} />
        <Route exact path={`${ROUTE_BACKEND_GAMES_EDIT}/:id`} element={<RouteBackendUser Component={PageBackendGame} />} />
        <Route exact path={ROUTE_BACKEND_SCORES} element={<RouteBackendUser Component={PageBackendScores} />} />
        <Route exact path={ROUTE_BACKEND_USERS} element={<RouteBackendAdmin Component={PageBackendUsers} />} />

        <Route exact path={ROUTE_BACKEND_SIGNIN} element={<PageBackendSignIn />} />
        <Route exact path={ROUTE_BACKEND_SIGNIN + "/:email"} element={<PageBackendSignIn />} />
        <Route exact path={ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD} element={<PageBackendChangePassword />} />
        <Route exact path={ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD + "/:email"} element={<PageBackendChangePassword />} />
        <Route exact path={ROUTE_BACKEND_PASSWORD_FORGOT} element={<PageBackendResetPassword />} />
        <Route exact path={ROUTE_BACKEND_PASSWORD_FORGOT + "/:email"} element={<PageBackendResetPassword />} />
        <Route exact path={ROUTE_BACKEND_PASSWORD_FORGOT_CONFIRM} element={<PageBackendResetPasswordConfirm />} />
        <Route exact path={ROUTE_BACKEND_PASSWORD_FORGOT_CONFIRM + "/:email"} element={<PageBackendResetPasswordConfirm />} />

        <Route exact path={ROUTE_NOTFOUND} element={<PageGeneralNotFound />} />
        <Route path={ROUTE_CATCH} element={<PageGeneralNotFound />} />
    </Routes>
);

// navigation items
const navigationItems = [
    // pages for backend users
    { path: ROUTE_BACKEND_HOME, element: <RouteBackendUser Component={PageBackendHome} />, routeType: null, label: "Dashboard", icon: <Home size={16} /> },
    { path: ROUTE_BACKEND_GAMES, element: <RouteBackendUser Component={PageBackendGames} />, routeType: null, label: "Spiele", icon: <DeviceGamepad size={16} /> },
    { path: ROUTE_BACKEND_SCORES, element: <RouteBackendUser Component={PageBackendScores} />, routeType: null, label: "Rangliste", icon: <Graph size={16} /> },

    // pages for backend admins
    { path: ROUTE_BACKEND_USERS, element: <RouteBackendAdmin Component={PageBackendUsers} />, routeType: [USERGROUP_ADMIN], label: "User", icon: <Users size={16} /> },
];

/**
 * implementation of app shell wrapper
 * @returns JSX
 */
export default function AppShellWrapper() {

    // globals
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const { pathname } = useLocation();
    const user = useUserState();
    const setError = useErrorDispatch();
    const setUser = useUserDispatch();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const [appLoading, setAppLoading] = useState(true);
    const location = useLocation();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`);

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        // listen to auth events
        const hubListener = Hub.listen('auth', async (data) => {
            if (data.payload.event === 'signIn') {
                await fetchUserDetails();
            }
        });

        // fetch user details
        fetchUserDetails().finally(() => {
            setAppLoading(false);
        });

        // unsubscribe from listeners when unmounting
        return () => {
            hubListener();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to get user details
     */
    const fetchUserDetails = async () => {
        // get user
        var authenticatedUser = null;
        try {
            authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        }
        catch {
            // an exception is thrown if no user is logged in, in that case just return;
            return;
        }

        // get user details
        try {
            // get user group
            var userGroup = getUserGroup(authenticatedUser);

            // set new user data
            setUser({
                action: USER_SET,
                values: {
                    id: authenticatedUser.attributes.sub,
                    email: authenticatedUser.attributes.email,
                    userGroup: userGroup,
                }
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    }

    /**
     * gets the user group from the user
     * @param {*} authenticatedUser the authenticated user
     * @returns the user group
     */
    const getUserGroup = (authenticatedUser) => {
        var userGroups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
        if (userGroups && userGroups.length > 0) {
            if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_ADMIN)) {
                return USERGROUP_ADMIN;
            }

            if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_USER)) {
                return USERGROUP_USER;
            }
        }

        // if the user group is still not set, we can't do anything else then throw an error
        throw new Error("user group could not be determinded");
    }

    /**
     * handling sign out of the user
     */
    const signOut = async () => {
        setLoading(LOADING_SHOW);
        Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: user.id });
        await clearDataStore();
        Auth.signOut().then(async () => {
            setUser(USER_RESET);
            navigate(ROUTE_BACKEND);
            showNotification({ message: "Erfolgreich abgemeldet.", color: 'green', icon: <Check /> });
        }).finally(() => {
            setLoading(LOADING_RESET);
        });
    }

    /**
     * generates a menu item
     * @param {Icon} icon the icon to show 
     * @param {string} color the color for the menu item
     * @param {string} label text to show for the menu item
     * @param {string} to the route to move to
     * @returns JSX for menu item
     */
    function MainLink({ icon, color, label, to }) {
        var currentPath = location.pathname.startsWith(to);

        return (
            <UnstyledButton
                component={Link}
                to={to}
                sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing.xs,
                    marginTop: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                    backgroundColor: currentPath ? (theme.colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[1]) : theme.colors.transparent,

                    '&:hover': {
                        backgroundColor: !currentPath ? (theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]) : null,
                    },
                })}
                onClick={() => { setOpened(false); }}
            >
                <Group>
                    <ThemeIcon color={color} variant="filled">{icon}</ThemeIcon>
                    <Text size="sm">{label}</Text>
                </Group>
            </UnstyledButton>
        );
    }

    /**
     * gets the navigation items for the navigation depending on users group
     */
    function getNavigationItems() {
        var filtered = navigationItems.filter(item => (item.routeType === null || item.routeType.includes(user.userGroup)));
        return filtered.map((e) => <MainLink icon={e.icon} color="blue" key={e.path} label={e.label} to={e.path} />)
    }

    /**
     * gets the header logo and text
     */
    function getHeader() {
        return (
            <img src={MyHeimTierLandBanner} fit="contain" height={60} alt="myheimtierland_banner" />
        );
    }

    // if the app is loading, show loading screen
    if (appLoading) {
        return (
            <LoadingOverlay loading={true} />
        );
    }

    return (
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <Notifications />
            <ScrollToTop />
            <ErrorOverlay />
            <ModalsProvider>
                <LoadingOverlay>
                    {pathname.startsWith(ROUTE_BACKEND) ?
                        <AppShell
                            padding="md"
                            navbarOffsetBreakpoint="sm"
                            navbar={
                                <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
                                    {mediaQueryLargerSm ?
                                        <Navbar.Section>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {getHeader()}
                                            </div>
                                            <Divider mt={20} mb="xs" />
                                        </Navbar.Section>
                                        : null
                                    }

                                    <Navbar.Section grow component={ScrollArea}>
                                        {getNavigationItems()}
                                    </Navbar.Section>
                                    <Divider mt="xs" pb="xs" />

                                    {!user.id ?
                                        <Navbar.Section>
                                            <UnstyledButton
                                                component={Link}
                                                to={ROUTE_BACKEND_SIGNIN}
                                                sx={(theme) => ({
                                                    display: 'block',
                                                    width: '100%',
                                                    padding: theme.spacing.xs,
                                                    borderRadius: theme.radius.sm,
                                                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                                                    '&:hover': {
                                                        backgroundColor:
                                                            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                                    },
                                                })}
                                                onClick={() => { setOpened(false); }}
                                            >
                                                <Group>
                                                    <ThemeIcon color="green" variant="filled"><Login size={16} /></ThemeIcon>
                                                    <Text size="sm">Anmelden</Text>
                                                </Group>
                                            </UnstyledButton>
                                        </Navbar.Section>
                                        :
                                        <Navbar.Section>
                                            <UnstyledButton
                                                sx={(theme) => ({
                                                    display: 'block',
                                                    width: '100%',
                                                    padding: theme.spacing.xs,
                                                    borderRadius: theme.radius.sm,
                                                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                                                    '&:hover': {
                                                        backgroundColor:
                                                            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                                    },
                                                })}
                                                onClick={async () => {
                                                    setOpened(false);
                                                    signOut();
                                                }}
                                            >
                                                <Group>
                                                    <ThemeIcon color="red" variant="filled"><Logout size={16} /></ThemeIcon>
                                                    <Text size="sm">Abmelden</Text>
                                                </Group>
                                            </UnstyledButton>
                                            <Text pt="xs" pl="xs" pr="xs" color="dimmed" size="xs">Angemeldet als:</Text>
                                            <Text pl="xs" pr="xs" color="dimmed" size="xs">{user.email}</Text>
                                        </Navbar.Section>
                                    }
                                </Navbar>
                            }
                            header={!mediaQueryLargerSm &&
                                <Header height={70} p="md">
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                        {getHeader()}
                                        <Burger
                                            opened={opened}
                                            onClick={() => setOpened((o) => !o)}
                                            size="sm"
                                            color={theme.colors.gray[6]}
                                            ml="auto"
                                        />
                                    </div>
                                </Header>
                            }
                        >
                            {routes}
                        </AppShell>
                        :
                        routes
                    }
                </LoadingOverlay>
            </ModalsProvider>
        </MantineProvider>
    );
}
