import { Button, Center, Flex, Group, Modal, Paper, Popover, Stack, Text, TextInput, Title } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { DataStore } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Check, Gift, InfoCircle, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import GameCard from "../../components/GameCard";
import { getBase64FromPath } from "../../helpers/Files";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_SCORE_NAME } from "../../helpers/Validation";
import { Game, Score } from '../../models';
import * as Yup from 'yup';

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_SCORE_NAME,
    email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * shuffles the cards
 * @param {array} array array of cards
 * @returns array
 */
function shuffleCards(array) {
    const length = array.length;
    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const currentIndex = i - 1;
        const temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
    }
    return array;
}

/**
 * implementation of game page
 * @returns JSX
 */
export default function PageGame() {

    // globals
    const [settings, setSettings] = useState(null);
    const [grid, setGrid] = useState({ cols: 0, rows: 0, dimension: 0 });
    const [cards, setCards] = useState(null);
    const [openCards, setOpenCards] = useState([]);
    const [clearedCards, setClearedCards] = useState({});
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
    const [moves, setMoves] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const timeout = useRef(null);
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const { id } = useParams();
    const setError = useErrorDispatch();
    const fetchedRef = useRef(false);
    const { ref, width, height } = useElementSize();
    const setLoading = useLoadingDispatch();

    // timer calculations
    const hours = Math.floor(time / 360000);
    const minutes = Math.floor((time % 360000) / 6000);
    const seconds = Math.floor((time % 6000) / 100);
    const milliseconds = time % 100;

    /**
     * use effect hook to fetch data
     */
    useEffect(() => {
        // check if already fetching
        if (fetchedRef.current === true) {
            return;
        }
        fetchedRef.current = true;

        initGame(id);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    /**
     * use effect hook to calculate grid rows, cols and element dimension
     */
    useEffect(() => {
        if (!ref || !height || !width || !cards) {
            return;
        }

        // calculate values for cols and rows of the grid
        var squareRoot = Math.sqrt(cards.length);
        var cols = Math.ceil(squareRoot);
        var rows = Math.floor(squareRoot);

        // first of all, check if the cols and row are equal, which means we do have a perfectly fitting grid already
        // if this is not the case, we are going to adapt the number of rows and cols according to screen dimensions
        // to adapt better to portrait or landscape rotation of the screen
        if (cols !== rows && rows !== 1) {
            if (width > height) {
                cols += 1;
                rows -= 1;
            }
            else {
                rows += 1;
                cols -= 1;
            }
        }

        // set grid
        setGrid({ cols: cols, rows: rows, dimension: Math.min(width / cols, height / rows) })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [width, height, ref, cards]
    );

    /**
     * fetches the settings for the game
     */
    const initGame = async (id) => {

        // if no game ID is provided we cannot load any game
        // we are showing the message in the body instead of an error, as message to the calling user.
        if (!id) {
            return;
        }

        try {
            // set loading
            setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);

            // fetch game settings from backend
            const game = await DataStore.query(Game, id);

            // check if game exists
            if (!game) {
                throw new Error("Spiel konnte nicht gefunden werden. Bitte prüfen Sie die URL.");
            }

            // download card backgroud image if set
            var cardBackgroundImage = null;
            if (game.cardBackgroundImage) {
                cardBackgroundImage = await getBase64FromPath(game.cardBackgroundImage);
            }

            // check if card images are set
            if (!game.cardImages || game.cardImages.length === 0) {
                throw new Error("no card images set");
            }

            // get card images
            var cardImagesPromises = [];
            for (const cardImage of game.cardImages) {
                cardImagesPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        const base64 = await getBase64FromPath(cardImage);
                        resolve(base64);
                    }
                    catch (err) {
                        reject(err);
                    }
                }))
            }
            const cardImages = await Promise.all(cardImagesPromises);

            // map cards and duplicate
            var cards = cardImages.map((cardImage, index) => {
                return {
                    index: index,
                    image: cardImage,
                }
            });
            cards = cards.concat(cards);

            // set state
            setNewCards(cards);
            setSettings({
                id: game.id,
                title: game.title,
                description: game.description,
                winText: game.winText,
                backgroundColor: game.backgroundColor,
                cardBackgroundImage: cardBackgroundImage,
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * shuffle and set cards
     * @param {array} cards array of cards
     */
    const setNewCards = (cards) => {
        setCards(shuffleCards(cards));
    }

    /**
     * use effect hook to update timer
     */
    useEffect(() => {
        let intervalId;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setTime(time + 1), 10);
        }
        return () => clearInterval(intervalId);
    },
        [isRunning, time]
    );

    /**
     * use effect hook to handle timeouts when opnening cards
     */
    useEffect(() => {
        let timeout = null;

        // if there are 2 cards open, we need to close them after a timeout
        if (openCards.length === 2) {
            timeout = setTimeout(evaluate, 300);
        }

        // callback to clear timeout
        return () => {
            clearTimeout(timeout);
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [openCards]
    );

    /**
     * use effect hook to check if cleared cards change, if the game is completed
     */
    useEffect(() => {
        checkCompletion();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clearedCards]
    );

    /**
     * wrapper to disable all cards
     */
    const disable = () => {
        setShouldDisableAllCards(true);
    };

    /**
     * wrapper to enable all cards
     */
    const enable = () => {
        setShouldDisableAllCards(false);
    };

    /**
     * checks if we have won the game
     */
    const checkCompletion = () => {
        if (cards && Object.keys(clearedCards).length === cards.length / 2) {
            setShowModal(true);
            setIsRunning(false);
        }
    };

    /**
     * evaluates a match
     */
    const evaluate = () => {
        const [first, second] = openCards;
        enable();

        if (cards[first].index === cards[second].index) {
            // we have found a match, save it
            setClearedCards((prev) => ({ ...prev, [cards[first].index]: true }));
        }

        // flip back if no match, after a timeout
        timeout.current = setTimeout(() => {
            setOpenCards([]);
        }, 700);
    };

    /**
     * handles a card click
     * @param {int} index index of the card
     */
    const handleCardClick = (index) => {
        // start timer
        if (!isRunning) {
            setIsRunning(true);
        }

        if (openCards.length === 1) {
            // if there is only one card open, set the open state internal
            setOpenCards((prev) => [...prev, index]);
            setMoves((moves) => moves + 1);
            disable();
        } else {
            // otherwise handle open card and timeout state
            clearTimeout(timeout.current);
            setOpenCards([index]);
        }
    };

    /**
     * checks if a card is flipped
     * @param {int} index index of the card
     * @returns boolean
     */
    const checkIsFlipped = (index) => {
        // check if card is currently open
        if (openCards.includes(index)) {
            return true;
        }

        // check if card match had already been found
        if (checkIsInactive(cards[index])) {
            return true;
        }

        // otherwise return false;
        return false;
    };

    /**
     * checks if a card is inactive, which means already matched
     * @param {object} card the card object
     * @returns boolean
     */
    const checkIsInactive = (card) => {
        return Boolean(clearedCards[card.index]);
    };

    /**
     * wrapper to handle restart of the game
     */
    const handleRestart = async () => {
        try {
            // show loading
            setLoading(LOADING_SHOW);

            // reset game set
            setClearedCards({});
            setOpenCards([]);
            setShowModal(false);
            setMoves(0);
            setShouldDisableAllCards(false);
            setTime(0);
            setIsRunning(false);

            // wait for the cards to turn back
            await new Promise(resolve => setTimeout(resolve, 500));

            // set new cards
            setNewCards(cards);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    };

    /**
     * gets the running game time as formatted string
     * @returns formatted timer string
     */
    const getTime = () => {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds.toString().padStart(2, "0")}`;
    }

    /**
     * submit callback to save score
     */
    const submitCallback = async (values) => {
        await DataStore.save(new Score({
            name: values.name,
            email: values.email.toLowerCase(),
            moves: moves,
            time: getTime(),
            gameId: id,
        }));

        // show success
        showNotification({ message: "Spielergebnis in Rangliste eingetragen.", color: 'green', icon: <Check /> });

        // reset the game
        form.reset();
        handleRestart();
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            email: "",
        },
        submitCallback: submitCallback
    });

    // show info on screen, if no game ID is set
    if (!id) {
        return (
            <Text m="md">Keine Spiel-ID angegeben. Bitte prüfen Sie den Link.</Text>
        )
    }

    return (
        <>
            <Flex
                justify="center"
                align="center"
                direction="column"
                wrap="wrap"
                gap="xs"
                className="page-wrapper"
                bg={settings?.backgroundColor}
            >
                <Paper p="xs" bg="white" w="100%" radius={0}>
                    {settings?.title && <Center><Title align="center" order={4}>{settings.title}</Title></Center>}
                    {settings?.description && <Center><Text align="center" size="sm" color="dimmed">{settings.description}</Text></Center>}
                    {settings &&
                        <Center mt="xs">
                            <Popover width={width} position="bottom" withArrow shadow="md">
                                <Popover.Target>
                                    <Button compact leftIcon={<InfoCircle size={16} />} variant="outline">Spielanleitung</Button>
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Text align="center" size="sm">Klicken Sie auf eine der Karten um diese umzudrehen. Auf der Unterseite befinden sich Motive, jedes Motiv befindet sich 2 Mal am Spielfeld. Finden Sie alle Paare um das Spiel erfolgreich abzuschließen.</Text>
                                </Popover.Dropdown>
                            </Popover>

                        </Center>
                    }
                </Paper>

                <div className="flex-wrapper" ref={ref}>
                    <div
                        className="grid-wrapper"
                        style={{
                            gridTemplateColumns: `repeat(${grid?.cols}, ${grid?.dimension}px)`,
                            gridTemplateRows: `repeat(${grid?.rows}, ${grid?.dimension}px)`,
                        }}
                    >
                        {cards?.map((card, index) => {
                            return (
                                <div style={{ padding: "1%", alignItems: "center", justifyContent: "center" }} key={index}>
                                    <GameCard
                                        card={card}
                                        index={index}
                                        isDisabled={shouldDisableAllCards}
                                        isFlipped={checkIsFlipped(index)}
                                        onClick={handleCardClick}
                                        backImage={settings.cardBackgroundImage}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <Paper p="xs" bg="white" w="100%" radius={0}>
                    {settings &&
                        <>
                            <Text size="sm" mb="xs" align="center">{`Zeit: ${getTime()}`}</Text>
                            <Center><Button compact color="green" variant="outline" leftIcon={<Reload size={14} />} onClick={() => handleRestart()}>Zurücksetzen</Button></Center>
                        </>
                    }
                </Paper>
            </Flex>

            <Modal
                opened={showModal}
                centered
                closeOnClickOutside={false}
                closeOnEscape={false}
                size="xl"
                withCloseButton={false}
            >
                <Stack>
                    <Stack spacing={0}>
                        <Center><Title>Spiel gewonnen!</Title></Center>
                        <Center><Text>{`${moves} Züge, benötigte Zeit ${getTime()}`}</Text></Center>
                        {settings?.winText && <Center><Text size="lg" mt="xl" mb="md" align="center">{settings.winText}</Text></Center>}
                    </Stack>

                    <form
                        onSubmit={form.onSubmit()}
                        onReset={form.onReset}
                    >
                        <Paper
                            withBorder
                            p="md"
                        >
                            <Stack>
                                <TextInput
                                    withAsterisk
                                    label="Name"
                                    placeholder="Vorname Nachname..."
                                    {...form.getInputProps('name')}
                                />
                                <TextInput
                                    withAsterisk
                                    label="Ihre E-Mail-Adresse..."
                                    placeholder="email@domain.xy..."
                                    {...form.getInputProps('email')}
                                />

                                <Group position='right'>
                                    <Button leftIcon={<Gift size={14} />} type="submit" color="green">In Rangliste eintragen</Button>
                                </Group>
                            </Stack>
                        </Paper>

                        <Group position='right' mt="md">
                            <Button leftIcon={<Reload size={14} />} color="yellow" type="reset" variant="outline" compact onClick={() => handleRestart()}>Erneut versuchen</Button>
                        </Group>
                    </form>
                </Stack>
            </Modal>
        </>
    )
}