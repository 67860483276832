import { Button, Card, Divider, Flex, Paper, SimpleGrid, Skeleton, Stack, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CustomizedRechartsDot, CustomizedRechartsLineChartTooltip, CustomizedRechartsXAxisTick, CustomizedRechartsYAxisTick } from "../../components/CustomRechartsComponents";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useUserState } from "../../helpers/GlobalUserState";
import { Score } from "../../models";
import moment from "../../helpers/Moment";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import Statistics from "../../components/Statistics";
import { fetchStats } from "../../helpers/Datastore";
import { ROUTE_BACKEND_SCORES } from "../../helpers/Routes";
import { Link } from "react-router-dom";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import { List } from "tabler-icons-react";
import Carousel from "../../components/Carousel";

/**
 * backend home page
 * @returns JSX
 */
export default function PageBackendHome() {

    // globals
    const user = useUserState();
    const setError = useErrorDispatch();
    const [scoresPerMonth, setScoresPerMonth] = useState(null);
    const [scoresStats, setScoresStats] = useState(null);
    const [scores, setScores] = useState(null);

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        try {
            // fetch data
            const scoresPerMonth = await fetchScoresPerMonth();
            const scoresStats = await fetchStats(Score);
            const scores = await fetchScores();

            // set data
            setScoresPerMonth(scoresPerMonth);
            setScoresStats(scoresStats);
            setScores(scores);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * fetches the latest scores
     */
    const fetchScores = async () => {
        // fetch scores
        const scores = await DataStore.query(
            Score,
            Predicates.ALL,
            {
                sort: s => s.createdAt(SortDirection.DESCENDING)
            }
        );

        // fetch details
        const data = [];
        for (const score of scores) {
            const game = await score.game;
            data.push({
                ...score,
                game: game,
            })
        }

        // return data
        return data;
    }

    /**
     * fetches the scores per month
     * @returns array with results
     */
    const fetchScoresPerMonth = async () => {
        // fetch data
        const months = 13;
        const scores = await DataStore.query(
            Score,
            (c) => c.createdAt.ge(moment().subtract(13, "months").format("YYYY-MM")),
        );

        // aggregate data
        const data = {};
        for (let i = months; i >= 0; i--) {
            const month = moment().subtract(i, "months").format("MMM YYYY");
            data[month] = 0;
        }
        scores.forEach((e) => {
            const month = moment(e.createdAt).format("MMM YYYY");
            data[month] += 1;
        })

        // map and return data
        const mappedData = Object.keys(data).map((key) => {
            return { name: key, value: data[key] };
        });
        return mappedData;
    }

    /**
     * wrapper to render score items
     * @param {array} items array of items to render
     * @returns JSX
     */
    const renderedScores = (items) => {
        const renderedScores = items.map(e => {
            return (
                <Card withBorder padding="lg" radius="md" style={{ height: "100%", display: "flex" }} key={e.id}>
                    <Flex gap="xs" direction="column" align="baseline" style={{ flexGrow: 1, display: "grid" }}>
                        <Flex gap="md" style={{ display: "flex" }}>
                            <Stack
                                spacing={0}
                                style={{
                                    alignSelf: "center"
                                }}
                            >
                                <Title order={5} pb="0px">Name: {e.name}</Title>
                                <Text color="dimmed" size="sm">Spiel: {e.game ? e.game.name : "-"}</Text>
                            </Stack>
                        </Flex>
                        <div style={{ alignSelf: "flex-end" }} >
                            <BadgeTimestamp label="Eingetragen am:" time={e.createdAt} />
                        </div>
                    </Flex>
                </Card>
            );
        })

        // add last item with link to search
        renderedScores.push(
            <Link to={ROUTE_BACKEND_SCORES} style={{ textDecoration: "none" }} key="scores_link">
                <Card withBorder padding="lg" radius="md" style={{ height: "100%", display: "flex" }}>
                    <Flex gap="xs" direction="column" align="baseline" style={{ flexGrow: 1, display: "grid" }}>
                        <Flex gap="md" style={{ display: "flex" }}>
                            <Stack
                                spacing={0}
                                style={{
                                    alignSelf: "center"
                                }}
                            >
                                <Title order={5} pb="0px">Zur Übersicht...</Title>
                            </Stack>
                        </Flex>
                        <Button
                            compact
                            variant="outline"
                            radius="xl"
                            leftIcon={<List size={14} />}
                            style={{ alignSelf: "flex-end" }}
                        >
                            Zur Übersicht
                        </Button>
                    </Flex>
                </Card>
            </Link>
        )

        // return items
        return renderedScores;
    }

    // as long as the data is not fetched, show skeletons
    if (!scoresPerMonth || !scoresStats || !scores) {
        return (
            <Stack>
                <Skeleton height={44.19} />
                <Skeleton height={1} />
                <Skeleton height={24.8} />
                <SimpleGrid
                    cols={6}
                    breakpoints={[
                        { maxWidth: 'lg', cols: 3 },
                        { maxWidth: 'md', cols: 2 },
                    ]}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
                <Skeleton height={300} />
                <Skeleton height={24.8} />
                <Skeleton height={177.59} />
            </Stack>
        );
    }

    return (
        <Stack>
            <Title>Dashboard</Title>
            <Divider />

            <Text color="dimmed">Eintragungen in Ranglisten</Text>
            <Statistics stats={scoresStats} />
            <Paper
                withBorder
                shadow="md"
                radius="md"
            >
                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        data={scoresPerMonth}
                        margin={{
                            top: 30,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 6" />
                        <XAxis dataKey="name" height={80} interval={0} tick={<CustomizedRechartsXAxisTick />} />
                        <YAxis tick={<CustomizedRechartsYAxisTick />} />
                        <Tooltip content={<CustomizedRechartsLineChartTooltip />} />
                        <Line type="monotone" dataKey="value" stroke="#008833" dot={<CustomizedRechartsDot />} />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>

            <Carousel title="Letzte Eintragungen in Ranglisten">
                {renderedScores(scores)}
            </Carousel>
        </Stack>
    )
}