// user groups
export const USERGROUP_ADMIN = "Admin";
export const USERGROUP_USER = "User";
export const USERGROUPS_ALL = "Alle";
export const USERGROUPS = [
    USERGROUP_USER,
    USERGROUP_ADMIN
]
export const USERGROUPS_WITHALL = [
    USERGROUPS_ALL,
    ...USERGROUPS,
]

// broadcasts
export const BC_CHANNEL = "bc_channel";
export const BC_TYPE_USERCHANGE = "bc_userchange";

// predicates
export const PREDICATE_CONTAINS = "CONTAINS";
export const PREDICATE_EQUALS = "EQ";
export const PREDICATE_GE = "GE";
export const PREDICATE_LT = "LT";

// table
export const TABLE_ACTION_WIDTH = 30;

// file types
export const FILE_SOURCE_INIT = "INIT";
export const FILE_SOURCE_SELECTED = "SELECTED";
export const FILE_TYPE_IMAGE = "image";
export const FILE_TYPE_PDF = "pdf";
export const FILE_TYPE_ACCEPT = {
    "image": { 'image/jpeg': [], 'image/jpg': [], 'image/png': [] },
    "pdf": { 'application/pdf': [] }
}

// avatar
export const AVATAR_SIZE = 150;
export const AVATAR_RADIUS = "md";

// misc
export const OPTION_YES = "Ja";
export const OPTION_NO = "Nein";
export const OPTION_ALL = "Alle";
export const OPTIONS_YESNO = [
    OPTION_YES,
    OPTION_NO
];
export const OPTIONS_YESNO_WITHALL = [
    OPTION_ALL,
    ...OPTIONS_YESNO,
];

// admin queries API
export const ADMIN_API_NAME = "AdminQueries";
export const ADMIN_API_LISTUSERS_INGROUP = "/listUsersInGroup";
export const ADMIN_API_CREATENEWUSER = "/createNewUser";
export const ADMIN_API_GETUSER = "/getUser";
export const ADMIN_API_ENABLEUSER = "/enableUser";
export const ADMIN_API_DISABLEUSER = "/disableUser";
export const ADMIN_API_CHANGEUSERADMINPERMISSIONS = "/changeUserAdminPermissions";
