import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";

/**
 * delete modal
 */
const deleteModal = (confirmCallback) => modals.openConfirmModal({
    title: 'Wirklich löschen?',
    children: (
        <Text size="sm">Möchten Sie den Eintrag wirklich löschen?</Text>
    ),
    labels: { confirm: 'Löschen', cancel: 'Abbrechen' },
    confirmProps: { color: "red" },
    onConfirm: confirmCallback,
});

export default deleteModal;