import { Select } from "@mantine/core";

/**
 * input for sort field selection
 * @param {object} props component props
 * @returns JSX
 */
export default function SortFieldInput(props) {

    return (
        <Select
            label="Sortieren nach"
            placeholder="..."
            clearable={false}
            creatable={false}
            {...props}
        />
    )
}