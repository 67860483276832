import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import awsConfig from './aws-exports';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import * as Sentry from "@sentry/react";

// configure amplify
Amplify.configure({
    ...awsConfig,
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    },
});

// sentry integration
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://bf09a35df7fb46518d7e17bb35869f6a@o672392.ingest.sentry.io/4504944812359680",
        normalizeDepth: 20,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
