import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { USERGROUP_ADMIN, USERGROUP_USER } from '../helpers/Constants';
import { useUserState } from '../helpers/GlobalUserState';
import { ROUTE_BACKEND_SIGNIN } from '../helpers/Routes';

/**
 * Private route implementation to redirect 
 * @param {array} props The props of the component
 */
const RouteBackendUser = ({ Component }) => {
    const location = useLocation();
    const user = useUserState();

    if (user.userGroup === USERGROUP_USER || user.userGroup === USERGROUP_ADMIN) {
        return <Component />
    }
    else {
        return <Navigate to={ROUTE_BACKEND_SIGNIN} state={{ from: location }} />
    }
}

// Default export of the class
export default RouteBackendUser;