import { Accordion, ActionIcon, Button, Divider, Group, Menu, Modal, Select, Stack, Table, Text, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Check, Filter, Plus, Reload, Settings, Star, StarOff, UserCheck, UserOff, X } from "tabler-icons-react";
import { Auth, API } from 'aws-amplify';
import * as Yup from 'yup';
import { showNotification } from "@mantine/notifications";
import { ADMIN_API_CHANGEUSERADMINPERMISSIONS, ADMIN_API_CREATENEWUSER, ADMIN_API_DISABLEUSER, ADMIN_API_ENABLEUSER, ADMIN_API_GETUSER, ADMIN_API_LISTUSERS_INGROUP, ADMIN_API_NAME, OPTIONS_YESNO_WITHALL, OPTION_ALL, OPTION_NO, OPTION_YES, USERGROUPS, USERGROUPS_ALL, USERGROUPS_WITHALL, USERGROUP_ADMIN, USERGROUP_USER } from "../../helpers/Constants";
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL, VALIDATION_SCHEMA_USERGROUP, VALIDATION_SCHEMA_USERGROUP_WITHALL } from "../../helpers/Validation";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useForm } from "../../components/Form";

var moment = require('moment-timezone');
moment.locale('de');

// initial values for user invite
const initialValuesCreateNewUser = {
    email: "",
    group: USERGROUP_USER,
};

// initial values for user filter
const initialValuesUserFilter = {
    email: "",
    enabled: OPTION_ALL,
    group: USERGROUPS_ALL,
};

// validation schema with yup for user invite
const validationSchemaCreateNewUser = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    group: VALIDATION_SCHEMA_USERGROUP,
});

// validation schema with yup for user filter
const validationSchemaUserFilter = Yup.object().shape({
    enabled: VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL,
    group: VALIDATION_SCHEMA_USERGROUP_WITHALL,
});
/**
 * page to administer backend users
 * @returns JSX
 */
export default function PageBackendUsers() {
    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [modalUserAddOpen, setModalUserAddOpen] = useState(false)
    const usersRef = useRef([]);

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        try {
            let fetchedUsers = [];
            for (let i = 0, len = USERGROUPS.length; i < len; i++) {
                const role = USERGROUPS[i];
                let more = true;
                let nextToken = '';

                while (more) {
                    let params = {
                        queryStringParameters: {
                            "groupname": role,
                            "limit": 60,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                        }
                    }

                    if (nextToken !== '') {
                        params.queryStringParameters.token = nextToken;
                    }

                    const rawUsers = await API.get(ADMIN_API_NAME, ADMIN_API_LISTUSERS_INGROUP, params);
                    const mapUsers = rawUsers.Users.map(user => {
                        return mapUserData(user, role);
                    });
                    fetchedUsers = fetchedUsers.concat(mapUsers);
                    if (rawUsers.hasOwnProperty('NextToken')) {
                        nextToken = rawUsers.NextToken;
                    } else {
                        more = false;
                    }
                }
                fetchedUsers.sort((a, b) => (a.email.localeCompare(b.email)));
            }

            usersRef.current = fetchedUsers;
            filterUsers();
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * maps a cognito user to a local user object
     * @param {object} user the cognito user object
     * @param {string} role the permissions group
     * @returns 
     */
    const mapUserData = (user, role) => {
        let atts = {}

        for (const att of user.Attributes) {
            atts[att.Name] = att.Value;
        }

        return {
            username: user.Username,
            email: atts.hasOwnProperty('email') ? atts.email : '',
            status: user.UserStatus,
            enabled: user.Enabled,
            createdAt: user.UserCreateDate,
            updatedAt: user.UserLastModifiedDate,
            group: role
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * changes the users enabled status
     * @param {string}  username    username to update
     * @param {boolean} enabled     enabled status
     */
    const changeUserEnabled = async (username, enabled) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user params
            let params = {
                body: {
                    "username": username,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }

            if (enabled) {
                await API.post(ADMIN_API_NAME, ADMIN_API_ENABLEUSER, params);
            } else {
                await API.post(ADMIN_API_NAME, ADMIN_API_DISABLEUSER, params);
            }

            // fetch users
            await fetchData();

            // show notification
            if (enabled) {
                showNotification({ message: "User aktiviert.", color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: "User deaktiviert", color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * changes the admin status of a user
     * @param {string}  username  username to update
     * @param {boolean} admin     admin status
     */
    const changeAdminPermission = async (username, admin) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user params
            let params = {
                body: {
                    "username": username,
                    "admin": admin
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }

            await API.post(ADMIN_API_NAME, ADMIN_API_CHANGEUSERADMINPERMISSIONS, params);

            // fetch users
            await fetchData();

            // show notification
            if (admin) {
                showNotification({ message: "Administrator Berechtigungen aktiviert.", color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: "Administrator Berechtigungen deaktiviert", color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * submit callback for invite form
     * @param {object} values form values
     */
    const createNewUserSubmitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            try {
                // check if user already exists
                let paramsUserExistsCheck = {
                    queryStringParameters: {
                        "username": values.email.toLowerCase(),
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                    }
                }
                const userExists = await API.get(ADMIN_API_NAME, ADMIN_API_GETUSER, paramsUserExistsCheck);

                // user exists
                if (userExists) {
                    setError({ action: ERROR_SHOW, error: new Error("User exisitiert bereits.") });
                    return;
                }
            }
            catch (err) {
                setError({ action: ERROR_SHOW, error: err });
            }

            // invite user
            let params = {
                body: {
                    "email": values.email.toLowerCase(),
                    "admin": values.group === USERGROUP_ADMIN ? true : false,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            await API.post(ADMIN_API_NAME, ADMIN_API_CREATENEWUSER, params);

            // handle status after invite
            await fetchData();
            inviteModalClose();
            showNotification({ message: "User eingeladen", color: 'green', icon: <Check /> });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * submit callback for user filter form
     * @param {object} values form values
     */
    const filterUserSubmitCallback = async () => {
        try {
            setLoading(LOADING_SHOW);
            filterUsers();

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * filters users depending on set filter in filter form
     */
    const filterUsers = () => {
        // start with all users
        var filteredUsers = [...usersRef.current];
        const values = userFilterForm.values;

        // filter email
        if (values.email) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.email.toLowerCase().includes(values.email.toLowerCase());
            });
        }

        // filter enabled state
        if (values.enabled === OPTION_YES) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === true;
            });
        }
        else if (values.enabled === OPTION_NO) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === false;
            });
        }

        // filter group
        if (values.group !== USERGROUPS_ALL) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.group === values.group;
            });
        }

        setFilteredUsers(filteredUsers);
    }

    /**
     * reset callback for user filter
     */
    const resetUserFilterCallback = () => {
        setFilteredUsers(usersRef.current);
    }

    // form hook for user invite
    const createNewUserForm = useForm({
        validationSchema: validationSchemaCreateNewUser,
        initialValues: initialValuesCreateNewUser,
        submitCallback: createNewUserSubmitCallback
    });

    // form hook for user filter
    const userFilterForm = useForm({
        validationSchema: validationSchemaUserFilter,
        initialValues: initialValuesUserFilter,
        submitCallback: filterUserSubmitCallback,
        resetCallback: resetUserFilterCallback,
    });

    /**
     * wrapper to handle invite modal close and reset form
     */
    const inviteModalClose = () => {
        createNewUserForm.setInitialValues(initialValuesCreateNewUser);
        setModalUserAddOpen(false);
    }

    /**
     * wrapper to map users in table
     */
    const rows = filteredUsers.map((element) => {
        return (
            <tr key={element.username}>
                <td>{element.email}</td>
                <td>{element.enabled ? "Ja" : "Nein"}</td>
                <td>{element.group}</td>
                <td>{element.status}</td>
                <td>{moment(element.createdAt).local().format("DD.MM.YYYY HH:mm")}</td>
                <td>{moment(element.updatedAt).local().format("DD.MM.YYYY HH:mm")}</td>
                <td>
                    <Menu position="bottom-end" width={220} withinPortal>
                        <Menu.Target>
                            <ActionIcon><Settings size={18} /></ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {element.enabled === true ?
                                <Menu.Item
                                    icon={<UserOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, false)}
                                >
                                    User deaktivieren
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<UserCheck size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, true)}
                                >
                                    User aktivieren
                                </Menu.Item>
                            }

                            {element.group === USERGROUP_ADMIN ?
                                <Menu.Item
                                    icon={<StarOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, false)}
                                >
                                    Admin-Zugang entfernen
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<Star size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, true)}
                                >
                                    Admin-Zugang erlauben
                                </Menu.Item>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        )
    });

    return (
        <>
            <Stack>
                <Group position="apart">
                    <Title>User</Title>
                    <Button
                        color="green"
                        leftIcon={<Plus />}
                        onClick={() => setModalUserAddOpen(true)}
                    >
                        Neuen User einladen
                    </Button>
                </Group>
                <Divider />

                <Accordion variant="contained">
                    <Accordion.Item value="filter">
                        <Accordion.Control icon={<Filter />}>Filtern</Accordion.Control>
                        <Accordion.Panel>
                            <form
                                onSubmit={userFilterForm.onSubmit()}
                                onReset={userFilterForm.onReset}
                            >
                                <Stack>
                                    <TextInput
                                        label="E-Mail-Adresse"
                                        placeholder="E-Mail-Adresse"
                                        {...userFilterForm.getInputProps('email')}
                                    />

                                    <Select
                                        label="Aktiv"
                                        placeholder="..."
                                        data={OPTIONS_YESNO_WITHALL}
                                        {...userFilterForm.getInputProps('enabled')}
                                    />

                                    <Select
                                        label="Gruppe"
                                        placeholder="Gruppe wählen..."
                                        data={USERGROUPS_WITHALL}
                                        {...userFilterForm.getInputProps('group')}
                                    />

                                    <Group mt="sm" position="apart">
                                        <Button variant="outline" color="yellow" leftIcon={<Reload />} type="reset">Zurücksetzen</Button>
                                        <Button variant="outline" color="green" leftIcon={<Filter />} type="submit">Filtern</Button>
                                    </Group>
                                </Stack>
                            </form>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Table striped highlightOnHover withBorder>
                    <thead>
                        <tr>
                            <th>E-Mail</th>
                            <th>Aktiv</th>
                            <th>Gruppe</th>
                            <th>Status</th>
                            <th>Erstellt am</th>
                            <th>Zuletzt geändert am</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </Stack>

            <Modal
                opened={modalUserAddOpen}
                onClose={() => setModalUserAddOpen(false)}
                title="Neuen User einladen"
                centered
                closeOnClickOutside={false}
                withCloseButton={false}
                size="xl"
            >
                <form
                    onSubmit={createNewUserForm.onSubmit()}
                    onReset={createNewUserForm.onReset}
                >
                    <Stack>
                        <Text color="dimmed" size="sm">Hiermit können Sie einen neuen User einladen. Wir senden ein temporäres Passwort an die E-Mail-Adresse, welches der User dann beim ersten Login ändern muss.</Text>
                        <TextInput
                            withAsterisk
                            label="E-Mail-Adresse"
                            placeholder="E-Mail-Adresse"
                            {...createNewUserForm.getInputProps('email')}
                        />

                        <Select
                            label="Berechtigung"
                            placeholder="Gruppe wählen..."
                            data={USERGROUPS}
                            {...createNewUserForm.getInputProps('group')}
                        />

                        <Group mt="sm" position="apart">
                            <Button color="yellow" leftIcon={<X size={14} />} onClick={() => inviteModalClose()}>Abbrechen</Button>
                            <Button color="green" leftIcon={<Plus size={14} />} type="submit">Einladen</Button>
                        </Group>
                    </Stack>
                </form>
            </Modal>
        </>
    )
}