import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * component to handle default state when the path changes
 */
function ScrollToTop() {
    const location = useLocation();

    // listener will fire when the history changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
}

export default ScrollToTop;