import { Center, Group, Paper, SimpleGrid, Text } from "@mantine/core";
import { ArrowDownRight, ArrowRight, ArrowUpRight } from "tabler-icons-react";

/**
 * wrapper to render application statistics
 * @param {object} props component props
 * @returns JSX
 */
export default function Statistics(props) {

    if (!props.stats) {
        return null;
    }

    const stats = props.stats.map((stat) => {
        // get icon and text color
        var textColor = "yellow";
        var DiffIcon = ArrowRight;
        if (stat.diff > 0) {
            textColor = "teal";
            DiffIcon = ArrowUpRight;
        }
        else if (stat.diff < 0) {
            textColor = "red";
            DiffIcon = ArrowDownRight;
        }
        else if (stat.diff === undefined) {
            textColor = "blue";
        }

        return (
            <Paper
                key={stat.title}
                p="md"
                radius="md"
                shadow="md"
            >
                <Center><Text size="xs" color="dimmed">{stat.title}</Text></Center>
                <Center>
                    <Center>
                        <Group spacing={10} mt="xs">
                            <Text
                                color={textColor}
                                style={{
                                    fontSize: "3rem",
                                    fontWeight: 700,
                                    lineHeight: 1,
                                }}
                            >
                                {stat.count}
                            </Text>
                            {stat.diff !== undefined &&
                                <Text
                                    color={textColor}
                                    fz="sm"
                                    fw={500}
                                    style={{
                                        alignSelf: "flex-end",
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>{(stat.diff * 100).toFixed(2)}%</span>
                                    <DiffIcon size={14} />
                                </Text>
                            }
                        </Group>
                    </Center>
                </Center>
            </Paper>
        );
    });

    return (
        <SimpleGrid
            cols={6}
            breakpoints={[
                { maxWidth: 'lg', cols: 3 },
                { maxWidth: 'md', cols: 2 },
            ]}
        >
            {stats}
        </SimpleGrid>
    );
}