import React, { useReducer } from 'react';

// action types
export const LOADING_SHOW = "show";
export const LOADING_SHOW_AND_HIDE_CHILDREN = "show_and_hide_children";
export const LOADING_RESET = "reset";

// default state
const initialState = {
    loading: false,
    hideChildren: false,
}

// context for global state
const globalLoadingContext = React.createContext(initialState);

// context for global dispatch
const dispatchLoadingContext = React.createContext(undefined);

// reducer state implementation
function reducer(state, action) {
    switch (action) {
        case LOADING_SHOW:
            return {
                ...state,
                loading: true,
                hideChildren: false,
            };
        case LOADING_SHOW_AND_HIDE_CHILDREN:
            return {
                ...state,
                loading: true,
                hideChildren: true,
            };
        case LOADING_RESET:
            return initialState;
        default:
            throw new Error(`Unhandled loading state reducer type: ${action}`);
    }
}

/**
 * The global state provider to wrap the app
 * @param {children} children The child components to render within the provider
 */
const GlobalLoadingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <globalLoadingContext.Provider value={state}>
            <dispatchLoadingContext.Provider value={dispatch}>
                {children}
            </dispatchLoadingContext.Provider>
        </globalLoadingContext.Provider>
    );
};

// exports 
export default GlobalLoadingProvider;
export const useLoadingState = () => React.useContext(globalLoadingContext);
export const useLoadingDispatch = () => React.useContext(dispatchLoadingContext);