import { Paper, Text, useMantineTheme } from "@mantine/core";
import { Carousel as MantineCarousel } from "@mantine/carousel";
import { v4 as uuidv4 } from 'uuid';

/**
 * custom carousel renderer
 * @param {object} props component props
 */
export default function Carousel(props) {

    // globals
    const theme = useMantineTheme();
    const color = theme.colorScheme === "light" ? theme.colors.gray[4] : theme.colors.dark[3];
    const colorIndicatorIcon = theme.colorScheme === "light" ? theme.colors.gray[8] : theme.colors.dark[0];

    return (
        <>
            {props.title && <Text color="dimmed">{props.title}</Text>}

            <Paper
                withBorder
                shadow="md"
                radius="md"
            >
                <MantineCarousel
                    align="start"
                    containScroll="trimSnaps"
                    slideGap="md"
                    slideSize="33.333333333333%"
                    withIndicators
                    includeGapInSize
                    breakpoints={[
                        { maxWidth: 'xl', slideSize: '33.333333333333%' },
                        { maxWidth: 'lg', slideSize: '100%' },
                    ]}
                    styles={{
                        control: {
                            '&[data-inactive]': {
                                opacity: 0.3,
                                cursor: 'default',
                            },
                            backgroundColor: color,
                            color: colorIndicatorIcon
                        },
                        indicator: {
                            backgroundColor: color
                        }
                    }}
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 35,
                        paddingTop: 15
                    }}
                    controlSize={20}
                    controlsOffset={5}
                >
                    {props.children.map(e => {
                        return (
                            <MantineCarousel.Slide key={uuidv4()}>
                                {e}
                            </MantineCarousel.Slide>
                        )
                    })}
                </MantineCarousel>
            </Paper>
        </>
    );
}