import { DataStore, Hub } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppShellWrapper from './components/AppShellWrapper';
import Broadcaster from './components/Broadcaster';
import LoadingOverlay from './components/LoadingOverlay';
import { BC_TYPE_USERCHANGE } from './helpers/Constants';
import GlobalErrorProvider from './helpers/GlobalErrorState';
import GlobalLoadingProvider from './helpers/GlobalLoadingState';
import GlobalUserProvider, { useUserState } from './helpers/GlobalUserState';

/**
 * app wrapper implementation
 * @returns JSX
 */
function App() {

    // globals
    const [dataStoreReady, setDataStoreReady] = useState(false);
    const datastoreListenerRef = useRef(null);
    const datastoreInitialzedBefore = useRef(false);
    const user = useUserState();

    /**
     * before we do anything, we need to make sure that the datastore is initialized
     */
    useEffect(() => {
        // listen to Datastore events 
        datastoreListenerRef.current = Hub.listen('datastore', async hubData => {
            const { event } = hubData.payload;
            if (event === 'ready' || (event.toLowerCase().includes("outbox") && datastoreInitialzedBefore.current === true)) {
                setDataStoreReady(true);
                datastoreInitialzedBefore.current = true;
            }
            else {
                setDataStoreReady(false);
            }
        });

        // listener for browser messages
        Broadcaster.onmessage = function (ev) {
            if (ev.data.type === BC_TYPE_USERCHANGE && ev.data.id !== user.id) {
                setTimeout(() => { window.location.reload(); }, 500);
            }
        }

        // init datastore
        DataStore.start();

        // unsubscribe from datastore listener when unmounting
        return () => {
            if (datastoreListenerRef.current) {
                datastoreListenerRef.current();
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // check if datastore loaded already
    if (!dataStoreReady) {
        return (
            <LoadingOverlay loading={true} />
        );
    }

    return (
        <GlobalErrorProvider>
            <GlobalLoadingProvider>
                <GlobalUserProvider>
                    <BrowserRouter>
                        <AppShellWrapper />
                    </BrowserRouter>
                </GlobalUserProvider>
            </GlobalLoadingProvider>
        </GlobalErrorProvider>
    );
}

export default App;
