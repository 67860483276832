import { Accordion, Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { Filter, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import List from "../../components/List";
import ModelSelectInput from "../../components/ModelSelectInput";
import SortDirectionInput from "../../components/SortDirectionInput";
import SortFieldInput from "../../components/SortFieldInput";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from "../../helpers/Constants";
import { getFilterPredicates } from "../../helpers/Predicates";
import { Game, Score } from "../../models";

/**
 * backend page for score overview
 * @returns JSX
 */
export default function PageBackendScores() {

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            email: "",
            gameId: "",
            createdAt: "",
            sortField: "createdAt",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
            { key: "email", value: form.values.email, type: PREDICATE_CONTAINS },
            { key: "gameId", value: form.values.gameId, type: PREDICATE_EQUALS },
        ]);
    }

    return (
        <Stack>
            <Title>Rangliste</Title>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    {...form.getInputProps('name')}
                                />

                                <TextInput
                                    label="E-Mail-Adresse"
                                    placeholder="E-Mail-Adresse"
                                    {...form.getInputProps('email')}
                                />

                                <ModelSelectInput
                                    type={Game}
                                    withAsterisk
                                    label="Spiel-Name"
                                    placeholder="Spiel-Name"
                                    {...form.getInputProps('gameId')}
                                    allOption
                                />

                                <SortFieldInput
                                    withAsterisk
                                    data={[
                                        { value: 'createdAt', label: 'Erstellzeit' },
                                        { value: 'name', label: 'Name' },
                                        { value: 'email', label: 'E-Mail-Adresse' },
                                        { value: 'score', label: 'Züge' },
                                        { value: 'time', label: 'Zeit' },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    withAsterisk
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group position='apart'>
                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={Score}
                id="id"
                dataStructure={[
                    { key: "name", title: "Name" },
                    { key: "email", title: "E-Mail-Adresse" },
                    { key: "game.name", title: "Spiel-Name" },
                    { key: "moves", title: "Züge" },
                    { key: "time", title: "Zeit" },
                    { key: "createdAt", title: "eingetragen am", type: "timestamp" },
                ]}
                delete={true}
                filter={getFilter()}
                sort={s => s[form.values.sortField](form.values.sortDirection)}
                export
                exportFileName="Rangliste_Export"
            />
        </Stack>
    );
}