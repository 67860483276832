import { Select } from "@mantine/core";
import { SortDirection } from "aws-amplify";

/**
 * input for sort direction
 * @param {object} props component props
 * @returns JSX
 */
export default function SortDirectionInput(props) {

    return (
        <Select
            label="Sortierung"
            placeholder="..."
            clearable={false}
            creatable={false}
            data={[
                { value: SortDirection.ASCENDING, label: 'Aufsteigend' },
                { value: SortDirection.DESCENDING, label: 'Absteigend' },
            ]}
            {...props}
        />
    )
}