// game routes
export const ROUTE_ROOT = "/";
export const ROUTE_GAME = "/game";

// administration routes
export const ROUTE_BACKEND = "/backend";
export const ROUTE_BACKEND_HOME = `${ROUTE_BACKEND}/home`;
export const ROUTE_BACKEND_GAMES = `${ROUTE_BACKEND}/games`;
export const ROUTE_BACKEND_GAMES_NEW = `${ROUTE_BACKEND_GAMES}/new`;
export const ROUTE_BACKEND_GAMES_EDIT = `${ROUTE_BACKEND_GAMES}/edit`;
export const ROUTE_BACKEND_SCORES = `${ROUTE_BACKEND}/scores`;
export const ROUTE_BACKEND_USERS = `${ROUTE_BACKEND}/users`;
export const ROUTE_BACKEND_SIGNIN = `${ROUTE_BACKEND}/signin`;
export const ROUTE_BACKEND_SIGNIN_CHANGEPASSWORD = `${ROUTE_BACKEND}/changepassword`;
export const ROUTE_BACKEND_SIGNUP = `${ROUTE_BACKEND}/signup`;
export const ROUTE_BACKEND_SIGNUP_CONFIRM = `${ROUTE_BACKEND_SIGNUP}/confirm`;
export const ROUTE_BACKEND_PASSWORD_FORGOT = `${ROUTE_BACKEND}/forgotpassword`;
export const ROUTE_BACKEND_PASSWORD_FORGOT_CONFIRM = `${ROUTE_BACKEND_PASSWORD_FORGOT}/confirm`;

// 404 routes
export const ROUTE_NOTFOUND = "/404";
export const ROUTE_CATCH = "*";
