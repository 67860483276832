import React, { useReducer } from 'react';

// modes for reducer
export const USER_SET = "set";
export const USER_RESET = "reset";

// default state
const initialState = {
    id: null,
    email: null,
    userGroup: null,
}

// context for global state
const globalUserContext = React.createContext(initialState);

// context for global dispatch
const dispatchUserContext = React.createContext(undefined);

// reducer state implementation
function reducer(state, newState) {
    if (newState === USER_RESET) {
        return initialState;
    }
    else if (newState?.action === USER_SET) {
        return {
            ...state,
            ...newState.values
        };
    }
    else {
        throw new Error(`Unhandled error state reducer info: ${JSON.stringify(newState)}`);
    }
}

/**
 * The global state provider to wrap the app
 * @param {children} children The child components to render within the provider
 */
const GlobalUserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <globalUserContext.Provider value={state}>
            <dispatchUserContext.Provider value={dispatch}>
                {children}
            </dispatchUserContext.Provider>
        </globalUserContext.Provider>
    );
};

// exports 
export default GlobalUserProvider;
export const useUserState = () => React.useContext(globalUserContext);
export const useUserDispatch = () => React.useContext(dispatchUserContext);