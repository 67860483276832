import React from "react";
import { Avatar, Paper } from "@mantine/core";
import "./GameCard.css";
import HeimTierLandLogo from '../images/myheimtierland_logo512.png';

const GameCard = ({ onClick, card, index, isFlipped, isDisabled, backImage }) => {

    const handleClick = () => {
        !isFlipped && !isDisabled && onClick(index);
    };

    return (
        <div className="flip-card">
            <Paper
                shadow="sm"
                radius="md"
                onClick={handleClick}
                className={`${isFlipped && "flip-card-rotate"} flip-card-inner`}
            >
                <div className="flip-card-front">
                    <Avatar classNames={{ image: 'non-selectable' }} size="100%" src={backImage ? backImage : HeimTierLandLogo} />
                </div>
                <div className="flip-card-back">
                    <Avatar classNames={{ image: 'non-selectable' }} size="100%" src={card.image} />
                </div>
            </Paper>
        </div>
    )
};

export default GameCard;