import { useMantineTheme } from '@mantine/core';

/**
 * Custom input component to render background as mantine input group
 * @param {object} props properties
 * @returns JSX
 */
export default function InputBackground(props) {

    // globals
    const theme = useMantineTheme();

    const style = {
        width: "100%",
        padding: "12px",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: !props.error ?
            theme.colorScheme === "light" ? theme.colors.gray[4] : theme.colors.dark[4]
            :
            theme.colorScheme === "light" ? theme.colors.red[6] : theme.colors.red[8],
        backgroundColor: theme.colorScheme === "light" ? theme.white : theme.colors.dark[6],
        marginBottom: "4px",
        ...props.style
    }

    return (
        <div style={style}>
            {props.children}
        </div>
    )
}
