import { Storage } from 'aws-amplify';

/**
 * handles S3 file upload
 * @param {object} file     the file
 * @param {string} fileName the file name to save as
 * @returns 
 */
export async function uploadFile(file, fileName, fileProtectionLevel, identityId) {
    try {
        await Storage.put(fileName, file, {
            level: fileProtectionLevel,
            contentType: file.type,
            identityId: identityId
        });
    }
    catch (e) {
        throw e;
    }


    return fileName;
}

/**
 * deletes a file from S3 by key
 * @param {string} key key of the file to delete
 * @param {string} fileProtectionLevel  the protection level
 * @param {string} identityId           the identity id of the user which owns the file
 */
export async function deleteFile(key, fileProtectionLevel, identityId) {
    await Storage.remove(key, {
        level: fileProtectionLevel,
        identityId: identityId ? identityId : null
    });
    return key;
}

/**
 * gets a presigned url for a file key
 * @param {string} key                  key of the file
 * @param {string} fileProtectionLevel  the protection level
 * @param {string} identityId           the identity id of the user which owns the file
 * @returns url
 */
export async function getFileUrl(key, fileProtectionLevel, identityId) {
    const result = await Storage.get(key, {
        level: fileProtectionLevel,
        identityId: identityId ? identityId : null
    });
    return result;
}

/**
 * Gets the data from the blob
 * @access public
 * @param  {Blob}   blob    The blob to get the data from
 * @return {Data}   The data from the blob
*/
export async function getDataFromBlob(blob) {
    //read the blob data
    return new Promise((resolve, reject) => {
        if (!blob) {
            resolve(undefined);
        };

        var reader = new FileReader();

        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);

        reader.onerror = () => {
            return undefined;
        };

        reader.readAsDataURL(blob);
    });
}

/**
 * wrapper to download a file by URL
 * @param {string} url url to download
 * @param {string} filename file name to save the file as
 */
export async function downloadFileFromUrl(url, filename) {
    // fetch blob and download
    let blobData = await getBlobFromUrl(url);

    // Create blob link to download
    const downloadUrl = window.URL.createObjectURL(new Blob([blobData]),);
    const link = document.createElement('a');
    link.href = downloadUrl;

    // construct file name
    var filenameWithExtension = `${filename ? filename : "download"}.`;
    switch (blobData.type) {
        case "image/jpeg":
        case "image/jpg":
            filenameWithExtension += "jpg";
            break;
        case "image/png":
            filenameWithExtension += "png";
            break;
        case "application/pdf":
            filenameWithExtension += "pdf";
            break;
        default:
            throw new Error("unknown type from blob");
    }
    link.setAttribute('download', filenameWithExtension);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

/**
 * downloads the file blob from an url
 * @param {string} url url to download
 * @returns blob
 */
export async function getBlobFromUrl(url) {
    let response = await fetch(url, {
        method: 'GET',
    });

    // create blob
    return await response.blob();
}

/**
 * gets the base64 string representation for a file with given path on storage
 * @param {string} path path on storage
 * @returns base 64 file
 */
export async function getBase64FromPath(path) {
    const url = await getFileUrl(path);
    const blob = await getBlobFromUrl(url);
    const base64 = await fileToBase64(blob);
    return base64;
}

/**
 * converts a file to a base64 string
 * @param {object} file the file object to convert
 * @returns base64 of the provided files
 */
export async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
};