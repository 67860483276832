import React, { useReducer } from 'react';

// action types
export const ERROR_SHOW = "show";
export const ERROR_RESET = "reset";

// default state
const initialState = {
    error: null,
    title: null,
    callback: null,
}

// context for global state
const globalErrorContext = React.createContext(initialState);

// context for global dispatch
const dispatchErrorContext = React.createContext(undefined);

// reducer state implementation
function reducer(state, newState) {
    if (newState === ERROR_RESET) {
        return initialState;
    }
    else if (newState?.action === ERROR_SHOW) {
        return {
            ...state,
            ...newState
        };
    }
    else {
        throw new Error(`Unhandled error state reducer info: ${JSON.stringify(newState)}`);
    }
}

/**
 * The global state provider to wrap the app
 * @param {children} children The child components to render within the provider
 */
const GlobalErrorProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <globalErrorContext.Provider value={state}>
            <dispatchErrorContext.Provider value={dispatch}>
                {children}
            </dispatchErrorContext.Provider>
        </globalErrorContext.Provider>
    );
};

// exports 
export default GlobalErrorProvider;
export const useErrorState = () => React.useContext(globalErrorContext);
export const useErrorDispatch = () => React.useContext(dispatchErrorContext);