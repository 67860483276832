import { DataStore } from "aws-amplify";
import { ActionIcon, Button, Group, Table, Text } from "@mantine/core";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Download, Edit, ExternalLink, Trash } from "tabler-icons-react";
import { TABLE_ACTION_WIDTH } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import moment from "../helpers/Moment";
import deleteModal from "./DeleteModal";
import { CSVLink } from "react-csv";

/**
 * list component
 * @param {object} props component props
 * @returns JSX
 */
export default function List(props) {

    // globals
    const subscription = useRef(null);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const workTimeRegulationsRef = useRef(null);
    const jobCategoriesRef = useRef(null);
    const initialFetchRef = useRef(false);

    /**
     * Use effect hook to fetch data when filter or sorting changes
     */
    useEffect(() => {
        if (initialFetchRef.current) {
            debouncedFetchData(props.filter, props.sort, props.customFilter);
        }

        // unsubscribe from listeners when unmounting
        return () => {
            unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.filter, props.sort, props.customFilter]
    );

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData(props.filter, props.sort, props.customFilter);

        return () => {
            jobCategoriesRef.current?.unsubscribe();
            jobCategoriesRef.current = null;
            workTimeRegulationsRef.current?.unsubscribe();
            workTimeRegulationsRef.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to fetch data
     */
    const fetchData = async (filter, sort, customFilter) => {
        // fist unsubscribe of already running
        unsubscribe();

        // fetch data
        const items = await DataStore.query(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        );
        setItemsHandler(items);

        // subscription for changes
        subscription.current = DataStore.observeQuery(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        ).subscribe({
            next(snapshot) {
                setItemsHandler(snapshot.items, customFilter);
            },
            error(err) {
                setLoading(LOADING_RESET);
                setError({ action: ERROR_SHOW, error: err });
            }
        });
    }

    /**
     * wrapper to check and fetch sub objects if needed
     * @param {array} items the items to set
     */
    const setItemsHandler = async (items, customFilter) => {
        try {
            // get all columns in subobjects
            const filteredSubColumns = props.dataStructure.filter((e) => {
                return e.key.includes(".");
            });

            // check if we do have any sub columns
            if (filteredSubColumns.length === 0) {
                // we dont have any sub colums, set the items
                setItems(items);
            }
            else {
                // we do have sub columns so we need to fetch them
                // map sub columns
                const subColumns = filteredSubColumns.map((e) => {
                    const split = e.key.split(".");
                    return {
                        key: e.key,
                        path: split,
                    }
                });

                // iterate over all items and create promises
                var promises = [];
                items.forEach(item => {
                    // if we did not skip before, we need to generate the video
                    promises.push(new Promise(async (resolve, reject) => {
                        var subPromises = [];
                        subColumns.forEach(subColumn => {
                            subPromises.push(new Promise(async (resolve, reject) => {
                                try {
                                    const subLength = subColumn.path.length;
                                    var subObject = await item[subColumn.path[0]];
                                    if (!subObject) {
                                        resolve(null);
                                    }
                                    for (var i = 1; i < subLength - 1; i++) {
                                        subObject = await subObject[subColumn.path[i]]
                                    }
                                    resolve({ key: subColumn.key, value: subObject[subColumn.path[subLength - 1]] });
                                }
                                catch (err) {
                                    reject(err);
                                }
                            }));
                        });

                        // wait for subpromises
                        try {
                            const subPromiseResult = await Promise.all(subPromises);
                            var newItem = { ...item };
                            subPromiseResult.forEach(result => {
                                if (result) {
                                    newItem[result.key] = result.value;
                                }
                            });

                            resolve(newItem);
                        }
                        catch (err) {
                            reject(err);
                        }
                    }));
                });

                // wait for promises to execute
                var promiseResult = await Promise.all(promises);

                // execute custom filter if set
                if (customFilter) {
                    promiseResult = customFilter(promiseResult);
                }

                // set items
                setItems(promiseResult);
            }

            // set intial fetch to true
            initialFetchRef.current = true;
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = useRef(debounce(async (filter, sort, customFilter) => {
        fetchData(filter, sort, customFilter);
    }, 300)).current;

    /**
     * wrapper to unsubscribe
     */
    const unsubscribe = () => {
        if (subscription.current) {
            subscription.current.unsubscribe();
            subscription.current = null;
        }
    }

    /**
     * table header JSX
     */
    const tableHeader = <tr>
        {props.editRoute && <th></th>}
        {props.showRoute && <th></th>}
        {props.dataStructure.map((e) => (
            <th key={e.key}>{e.title}</th>
        ))}
        {props.delete && <th></th>}
    </tr>

    /**
     * renders a field in a column
     * @param {object} element data column info
     * @param {*} item item to render
     * @returns JSX
     */
    const renderField = (element, item) => {
        switch (element.type) {
            case "color":
                if (!item[element.key]) {
                    return "keine";
                }

                return <Group>
                    <div style={{ backgroundColor: item[element.key], height: "25px", width: "25px", borderRadius: "25px" }}></div>
                    <Text>{item[element.key]}</Text>
                </Group>;
            case "timestamp":
                var timestamp = moment(item[element.key]).local()
                if (timestamp.isValid()) {
                    return timestamp.format("DD.MM.YYYY HH:mm");
                }
                else {
                    return "";
                }
            case "date":
                var date = moment(item[element.key]).local()
                if (date.isValid()) {
                    return date.format("DD.MM.YYYY");
                }
                else {
                    return "";
                }
            case "count":
                return item[element.key].length;
            default:
                return item[element.key];
        }
    }

    /**
     * deletes an entry
     * @param {object} item the item to delete
     */
    const deleteEntry = async (item) => {
        try {
            if (props.deleteCallback) {
                await props.deleteCallback(item[props.id]);
            }
            else {
                await DataStore.delete(props.type, item[props.id]);
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * table rows JSX
     */
    const rows = items.map((item) => (
        <tr key={item[props.id]}>
            {props.editRoute &&
                <td
                    key={`${item[props.id]}_${"edit"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => navigate(`${props.editRoute}/${item[props.id]}`)}
                    >
                        <Edit />
                    </ActionIcon>
                </td>
            }
            {props.showRoute &&
                <td
                    key={`${item[props.id]}_${"show"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <a
                        href={`${props.showRoute}/${item[props.id]}`}
                        target={props.showExternal ? "_blank" : null}
                        rel={props.showExternal ? "noreferrer" : null}
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        <ActionIcon
                            color="blue"
                        >
                            <ExternalLink />
                        </ActionIcon>
                    </a>
                </td>
            }
            {props.dataStructure.map((element) => (
                <td key={`${item[props.id]}_${element.key}`}>{renderField(element, item)}</td>
            ))}
            {props.delete &&
                <td
                    key={`${item[props.id]}_${"delete"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="red"
                        onClick={() => deleteModal(() => deleteEntry(item))}
                    >
                        <Trash />
                    </ActionIcon>
                </td>
            }
        </tr>
    ));

    /**
     * wrapper to get export header for conversion to csv
     */
    const getExportHeader = () => {
        return props.dataStructure.map((e) => {
            return { label: e.title, key: e.key };
        })
    }

    return (
        <>
            {props.export &&
                <Group position="right">
                    <CSVLink
                        data={items}
                        separator=";"
                        headers={getExportHeader()}
                        filename={`${props.exportFileName ? props.exportFileName : "export"}_${moment().local().format("YYYY-MM-DD_HH-mm-ss")}`}
                    >
                        <Button compact variant="outline" leftIcon={<Download size={14} />}>Daten exportieren</Button>
                    </CSVLink>
                </Group>
            }
            <Table striped highlightOnHover withBorder>
                <thead>{tableHeader}</thead>
                <tbody>{rows}</tbody>
            </Table>
        </>
    )
}