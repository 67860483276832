import { Flex, Paper, Text } from '@mantine/core';
import React from 'react';
import { useMantineTheme } from '@mantine/styles';
import { Surface, Symbols } from 'recharts';
import { useMediaQuery } from '@mantine/hooks';

// globals
const RADIAN = Math.PI / 180;
const borderRadius = 10;

/**
 * implementation of custom x axis tick component for Recharts
 */
export function CustomizedRechartsXAxisTick(props) {
  const { x, y, payload } = props;
  const theme = useMantineTheme();
  const color = theme.colorScheme === "light" ? theme.black : theme.colors.dark[0];

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill={color} transform="rotate(-45)" fontSize={12}>
        {payload.value}
      </text>
    </g>
  );
}

/**
 * implementation of custom y axis tick component for Recharts
 */
export function CustomizedRechartsYAxisTick(props) {
  const { x, y, payload } = props;
  const theme = useMantineTheme();
  const color = theme.colorScheme === "light" ? theme.black : theme.colors.dark[0];

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill={color} fontSize={12}>
        {payload.value}
      </text>
    </g>
  );
}

/**
 * implementation of custom label component for Recharts
 */
export const CustomizedRechartsDot = (props) => {
  const { cx, cy } = props;
  const theme = useMantineTheme();
  const colorCircle = theme.colorScheme === "light" ? theme.colors.gray[3] : theme.colors.dark[3];

  return (
    <g>
      <circle cx={cx} cy={cy} r={4} fill={colorCircle} stroke="black" />
    </g>
  );
};

/**
 * implementation of custom tooltip component for Recharts
 */
export const CustomizedRechartsLineChartTooltip = (props) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <Paper
        withBorder
        shadow="md"
        p="xs"
        radius="md"
      >
        <Text>{label}</Text>
        {payload.map(e => {
          return <Text size="sm" key={e.name}>{`${e.name}: ${e.value}`}</Text>
        })}
      </Paper>
    );
  }

  return null;
};

/**
 * custom label renderer
 * @param {props} props component props
 * @returns text to render for label
 */
export const RenderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const theme = useMantineTheme();
  const color = theme.colorScheme === "light" ? theme.black : theme.colors.dark[0];

  return (
    <text x={x} y={y} fill={color} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={12}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

/**
 * custom legend for pie chart
 * @param {object} props component props
 * @returns legend
 */
export const CustomizedPieLegend = (props) => {
  const { payload } = props;
  const theme = useMantineTheme();
  const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`);
  const color = theme.colorScheme === "light" ? theme.colors.gray[3] : theme.colors.dark[4];
  const height = mediaQueryLargerSm ? "460px" : "200px";
  const maxWidth = mediaQueryLargerSm ? "300px" : "unset";
  const rightOffset = mediaQueryLargerSm ? -5 : "unset";
  const borderRadiusRight = mediaQueryLargerSm ? 0 : borderRadius;
  const borderRight = `${mediaQueryLargerSm ? "0px" : "1px"} solid ${color}`;

  return (
    <div
      style={{
        position: "relative",
        padding: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        border: `1px solid ${color}`,
        borderRight: borderRight,
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        borderTopRightRadius: borderRadiusRight,
        borderBottomRightRadius: borderRadiusRight,
        maxHeight: height,
        maxWidth: maxWidth,
        overflow: 'auto',
        right: rightOffset,
      }}
    >
      {payload.map((entry) => {
        const { value, color } = entry;
        var text = value;
        if (entry.payload.value !== undefined) {
          text = `${value} (${entry.payload.value})`;
        }

        return (
          <Flex key={value}>
            <div style={{ width: 14, marginRight: "8px", paddingTop: 2 }}>
              <Surface
                width={14}
                height={14}
                viewBox={{ x: 0, y: 0, width: 32, height: 32 }}
                style={{
                  display: "inline-block",
                  verticalAlign: "text-top"
                }}
              >
                <Symbols
                  cx={16}
                  cy={16}
                  type={payload.type}
                  size={804.2}
                  fill={color}
                />
              </Surface>
            </div>
            <Text fz={14}>{text}</Text>
          </Flex>
        )
      })}
    </div>
  );
}