import { Input, Text, ColorPicker as MantineColorPicker, useMantineTheme, Group, Button } from '@mantine/core';
import { X } from 'tabler-icons-react';
import InputBackground from './InputBackground';

/**
 * custom ColorPicker with form wrapper
 * @param {object} props component properties
 * @returns 
 */
export default function ColorPicker(props) {

    // globals
    const theme = useMantineTheme();

    // colors to show
    var colors = [];
    Object.keys(theme.colors).forEach(key => {
        colors = colors.concat(theme.colors[key]);
    });

    /**
     * handler for date changes, automatically formats date
     * @param {date} e 
     */
    const onChange = (e) => {
        var values = {};

        if (!e) {
            // if no date was selected return empty string
            values[props.path] = null;
        }
        else {
            // if a date was selected, format it
            values[props.path] = e;
        }

        props.form.setValues({ ...values });
    }

    return (
        <Input.Wrapper error={props.error}>
            <Input.Label required={props.withAsterisk}>{props.label ? props.label : "Farbe"}</Input.Label>
            {props.description &&
                <Input.Description pb={5}>{props.description}</Input.Description>
            }
            <InputBackground error={props.error}>
                <Group position="apart">
                    <Text size="sm" mb="sm">{`Farbe: ${props.form.values[props.path] ? props.form.values[props.path] : "auswählen..."}`}</Text>
                    <Button
                        color="red"
                        compact
                        size="sm"
                        leftIcon={<X size={14} />}
                        style={{ marginBottom: "auto" }}
                        onClick={() => onChange(null)}
                    >
                        entfernen
                    </Button>
                </Group>
                <MantineColorPicker
                    format="hexa"
                    size="xl"
                    swatches={colors}
                    fullWidth
                    swatchesPerRow={20}
                    {...props.form.getInputProps(props.path, { withError: props.withError })}
                    onChange={(e) => onChange(e)}
                    value={props.form.values[props.path]}
                />
            </InputBackground>
        </Input.Wrapper>
    )
}