import * as Yup from 'yup';
import { VALIDATION_SCHEMA_GAME_CARDIMAGES, VALIDATION_SCHEMA_GAME_NAME } from '../../helpers/Validation';
import { useNavigate, useParams } from "react-router-dom";
import { Game } from '../../models';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { DataStore } from '@aws-amplify/datastore';
import { useForm } from '../../components/Form';
import { useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import { Check, File, List, Reload, Trash } from 'tabler-icons-react';
import { Button, Divider, Group, Stack, TextInput, Title } from '@mantine/core';
import { ROUTE_BACKEND_GAMES, ROUTE_NOTFOUND } from '../../helpers/Routes';
import ColorPicker from '../../components/ColorPicker';
import FileInput from '../../components/FileInput';
import { FILE_TYPE_IMAGE } from '../../helpers/Constants';
import { deleteGame } from '../../helpers/Datastore';
import deleteModal from '../../components/DeleteModal';

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_GAME_NAME,
    cardImages: VALIDATION_SCHEMA_GAME_CARDIMAGES
});

/**
 * page to create/update a game
 * @returns JSX
 */
export default function PageBackendGame() {

    // globals
    const navigate = useNavigate();
    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
                const item = await DataStore.query(Game, id);
                if (!item) {
                    navigate(ROUTE_NOTFOUND);
                }

                // set data
                form.setInitialValues({
                    id: item.id,
                    name: item.name,
                    title: item.title,
                    description: item.description,
                    winText: item.winText,
                    backgroundColor: item.backgroundColor,
                    cardBackgroundImage: item.cardBackgroundImage,
                    cardImages: item.cardImages,
                });
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // submit callback
    const submitCallback = async (values) => {
        // save item
        if (!id) {
            // create item
            await DataStore.save(new Game({
                name: values.name,
                title: values.title,
                description: values.description,
                winText: values.winText,
                backgroundColor: values.backgroundColor,
                cardBackgroundImage: values.cardBackgroundImage,
                cardImages: values.cardImages,
            }));
        } else {
            // update item
            const original = await DataStore.query(Game, id);
            await DataStore.save(Game.copyOf(original, updated => {
                updated.name = values.name;
                updated.title = values.title;
                updated.description = values.description;
                updated.winText = values.winText;
                updated.backgroundColor = values.backgroundColor;
                updated.cardBackgroundImage = values.cardBackgroundImage;
                updated.cardImages = values.cardImages;
            }));
        }

        // show success
        showNotification({ message: "Spiel erfolgreich gespeichert.", color: 'green', icon: <Check /> });

        // navigate to list
        navigate(ROUTE_BACKEND_GAMES);
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            id: "",
            name: "",
            title: "",
            description: "",
            winText: "",
            backgroundColor: "",
            cardBackgroundImage: null,
            cardImages: null,
        },
        submitCallback: submitCallback
    });

    /**
     * callback to delete game
     */
    const deleteCallback = async () => {
        if (!form.values.id) {
            return;
        }

        deleteModal(async () => {
            try {
                setLoading(LOADING_SHOW);
                await deleteGame(form.values.id);
                navigate(ROUTE_BACKEND_GAMES);
            }
            catch (e) {
                setError({ action: ERROR_SHOW, error: e });
            }
            finally {
                setLoading(LOADING_RESET);
            }
        })
    }

    // if there is a ID provided, we need to fetch first
    if (id && !form.values.id) {
        return null;
    }

    return (
        <Stack>
            <Title>{`Spiel ${form.values.id ? "bearbeiten" : "anlegen"}`}</Title>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="..."
                        {...form.getInputProps('name')}
                        description="Interner Name für das Spiel, wird dem Spielenden nicht angezeigt."
                    />
                    <TextInput
                        label="Titel"
                        placeholder="..."
                        {...form.getInputProps('title')}
                        description="Optionaler Titel des Spiels, wird über dem Spiel angezeigt."
                    />
                    <TextInput
                        label="Beschreibung"
                        placeholder="..."
                        {...form.getInputProps('description')}
                        description="Optionale Beschreibung des Spiels, wird über dem Spiel angezeigt."
                    />
                    <TextInput
                        label="Text bei erfolgreichem Lösen des Spiels"
                        placeholder="..."
                        {...form.getInputProps('winText')}
                        description="Optionaler Text, wenn das Spiel gelöst wird, wird im Spiel-Abschluss-Dialog angezeigt."
                    />
                    <ColorPicker
                        form={form}
                        path="backgroundColor"
                        withError={true}
                        description={"Hintergrundfarbe der Spielseite"}
                    />
                    <FileInput
                        label="Bild für die Rückseite der Karte"
                        path="cardBackgroundImage"
                        description="Optionales Bild für die Rückseite der Karten."
                        fileType={FILE_TYPE_IMAGE}
                        form={form}
                        initFiles={form.values.cardBackgroundImage}
                        storagePrefix={"games"}
                    />
                    <FileInput
                        withAsterisk
                        label="Bilder"
                        path="cardImages"
                        description="Bilder, die im Spiel gematched werden müssen. Bitte verwenden Sie möglichst quadratische Bilder, bei denen die Höhe ähnlich der Breite ist."
                        fileType={FILE_TYPE_IMAGE}
                        form={form}
                        initFiles={form.values.cardImages}
                        storagePrefix={"games"}
                        multiple
                    />
                    <Divider />

                    <Group position='apart'>
                        <Group>
                            <Button leftIcon={<Reload size={14} />} type="reset" color="yellow">Werte zurücksetzen</Button>
                            <Button leftIcon={<List size={14} />} onClick={() => navigate(ROUTE_BACKEND_GAMES)} color="blue">Zurück zur Liste</Button>
                            {form.values.id && <Button leftIcon={<Trash size={14} />} onClick={() => deleteCallback()} color="red">Spiel löschen</Button>}
                        </Group>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}