import React, { useEffect, useState } from 'react';
import { MultiSelect, Select } from '@mantine/core';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';

/**
 * generic model select input
 */
const ModelSelectInput = (props) => {
    
    // globals
    const [items, setItems] = useState([]);
    const setError = useErrorDispatch();

    // gather component props
    const componentProps = {
        error: props.error,
        label: props.label,
        onBlur: props.onBlur,
        onChange: props.onChange,
        onFocus: props.onFocus,
        placeholder: props.placeholder,
        value: props.value,
        withAsterisk: props.withAsterisk,       
    }

    /**
     * fetches the model entries from db
     * @returns array of results
     */
    const fetchData = async () => {
        try {
            const items = await DataStore.query(props.type, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            const mappedItems = items.map((e) => {
                return { value: e.id, label: e.name };
            })

            // add all option if set
            if (props.allOption) {
                mappedItems.unshift({ value: "", label: "Alle" });
            }

            // set items
            setItems(mappedItems);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }

    };

    /**
     * use effect hook to listen to fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // as long as there are no items fetched, return empty JSX
    if (items.length === 0) {
        return null;
    }

    if (!props.multiple) {
        return (
            <Select
                transitionProps={{
                    duration: 80,
                    timingFunction: "ease",
                    transition: "scale"
                }}
                searchable
                data={items}
                {...componentProps}
                type={null}
            />
        );
    }
    else {
        return (
            <MultiSelect
                transitionProps={{
                    duration: 80,
                    timingFunction: "ease",
                    transition: "scale"
                }}
                searchable
                data={items}
                {...componentProps}
                type={null}
            />
        );
    }
}

// Default export of the class
export default ModelSelectInput;