import { ActionIcon, Card, Flex, Group, Input, List, Paper, Stack, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { useEffect, useRef, useState } from "react";
import { Download, File, FileUpload, FileX, Trash, X } from "tabler-icons-react";
import { AVATAR_RADIUS, FILE_SOURCE_INIT, FILE_SOURCE_SELECTED, FILE_TYPE_ACCEPT, FILE_TYPE_IMAGE } from "../helpers/Constants";
import InputBackground from "./InputBackground";
import { v4 as uuidv4 } from 'uuid';
import { deleteFile, downloadFileFromUrl, getFileUrl, uploadFile } from "../helpers/Files";
import { useForceUpdate } from "@mantine/hooks";
import Avatar from "./Avatar";
import * as Sentry from "@sentry/react";

/**
 * implementation of file picker component to be used in parent forms
 * @param {string || array} initFiles the files to init the component with
 * @param {boolean} multiple mode single or multiple files
 * @param {string} label label to show
 * @param {string} description description of the component
 * @param {string} path name of the variable to set the image paths to
 * @param {object} form the parent form
 * @param {boolean} withAsterisk set if required, styled the component label
 * @param {string} fileType file type of files to be allowed
 * @param {int} maxSize max size for each file
 * @param {string} storagePrefix prefix for the storage path
 * @returns JSX
 */
export default function FileInput({ initFiles, multiple, label, description, path, form, withAsterisk, fileType, maxSize, storagePrefix }) {

    // globals
    const maxSizeBytes = maxSize ? maxSize : 10485760;
    const maxSizeMegaBytes = maxSizeBytes / 1024 / 1024;
    const accept = FILE_TYPE_ACCEPT[fileType];
    const filesRef = useRef(null);
    const [filesToRender, setFilesToRender] = useState(null);
    const initFilesRef = useRef([]);
    const filesToDeleteFromStorageRef = useRef([]);
    const forceUpdate = useForceUpdate();

    /**
     * Use effect hook to set inital files
     */
    useEffect(() => {
        // init files already set, discard
        if (filesRef.current !== null) {
            return;
        }

        // no init files from props, discard
        if (initFiles === null) {
            return;
        }

        // set init files
        if (multiple) {
            const files = initFiles.map(file => {
                return mapInitFile(file);
            });
            initFilesRef.current = files;
        }
        else {
            initFilesRef.current = [mapInitFile(initFiles)];
        }

        // set files to init files
        filesRef.current = [...initFilesRef.current];
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initFiles]
    );

    /**
     * Use effect hook to register additional callbacks
     */
    useEffect(() => {
        form.registerAdditionalOnReset(onReset);
        form.registerAdditionalOnSubmit(onSubmit);

        return () => {
            form.unregisterAdditionalOnReset(onReset);
            form.unregisterAdditionalOnSubmit(onSubmit);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * the submit handler
     */
    const onSubmit = async () => {
        // upload new files
        if (filesRef.current) {
            var uploadPromises = [];
            filesRef.current.forEach(file => {
                // only upload newly selected files
                if (file.fileSource !== FILE_SOURCE_SELECTED) {
                    return;
                }

                uploadPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        const uploadResult = await uploadFile(file.file, file.name);
                        resolve(uploadResult);
                    }
                    catch (e) {
                        reject(e);
                    }
                }))
            })
            await Promise.all(uploadPromises);
        }

        // delete files which where removed by user
        if (filesToDeleteFromStorageRef.current) {
            var deletePromises = [];
            filesToDeleteFromStorageRef.current.forEach(file => {
                deletePromises.push(new Promise(async (resolve) => {
                    try {
                        const deleteResult = await deleteFile(file);
                        resolve(deleteResult);
                    }
                    catch (e) {
                        Sentry.captureException(e);
                        resolve();
                    }
                }))
            })
            await Promise.all(deletePromises);
        }
    }

    /**
     * the reset handler
     */
    const onReset = () => {
        filesToDeleteFromStorageRef.current = [];
        filesRef.current = [...initFilesRef.current];
    }

    /**
     * Use effect hook to set form values
     */
    useEffect(() => {
        // get new value
        var newValues = null;
        if (multiple && filesRef.current && filesRef.current.length > 0) {
            newValues = filesRef.current.map(file => {
                return file.name;
            })
        }
        else if (!multiple && filesRef.current && filesRef.current.length > 0) {
            newValues = filesRef.current[0].name;
        }

        // set new value
        var values = {};
        values[path] = newValues;
        form.setValues({ ...values });

        // set files to render
        setFilesToRender(filesRef.current);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filesRef.current]
    );

    /**
     * get title for component
     */
    const getLabel = () => {
        if (label) {
            return label;
        }

        if (multiple) {
            return "Dateien";
        }

        return "Datei";
    }

    /**
     * wrapper to render dropzone content
     * @param {string} text 
     * @returns JSX
     */
    const getDropzoneContent = () => {
        var acceptedFilesText = null;
        if (accept) {
            const acceptedFiles = Object.keys(accept).map((k) => {
                return k;
            });
            acceptedFilesText = acceptedFiles.join(", ");
        }

        return (
            <>
                {acceptedFilesText && <Text size="sm" color="dimmed" inline>Mögliche Dateiformate: {acceptedFilesText}</Text>}
                <Text size="sm" color="dimmed" inline>Maximale Dateigröße je Datei: {maxSizeMegaBytes} MB</Text>
            </>
        );
    }

    /**
     * accepted files handler
     * @param {array} files files that had been accepted
     */
    const onAccept = (files) => {
        if (multiple) {
            const newFiles = filesRef.current ? [...filesRef.current] : [];
            files.forEach(file => {
                newFiles.push(mapSelectedFile(file));
            });
            filesRef.current = newFiles;
        }
        else {
            const newFiles = [mapSelectedFile(files[0])]
            filesRef.current = newFiles;
        }
        forceUpdate();
    }

    /**
     * maps a selected file to an internal object with additional information like source and generated name
     * @param {object} file the selected file
     * @returns file object with additional information
     */
    const mapSelectedFile = (file) => {
        return {
            fileSource: FILE_SOURCE_SELECTED,
            file: file,
            name: `${storagePrefix ? `${storagePrefix}/` : ""}${uuidv4()}_${file.name}`
        }
    }

    /**
     * maps an init file
     * @param {string} fileName file name
     * @returns mapped file
     */
    const mapInitFile = (fileName) => {
        return {
            fileSource: FILE_SOURCE_INIT,
            name: fileName,
        }
    }

    /**
     * handler for rejected files
     * @param {array} files array of dropped files that had been rejected
     */
    const onReject = (files) => {
        // get error code from first file, to check for too many files error
        if (files[0].errors[0].code === "too-many-files") {
            showNotification({ message: "Zu viele Dateien ausgewählt.", color: 'red', icon: <X /> });
            return;
        }

        // otherwise we need to check all errors
        const errors = [];
        files.forEach((file, fileIndex) => {
            // create error struct
            errors[fileIndex] = {
                name: file.file.name,
                errors: []
            };

            // add errors
            file.errors.forEach(error => {
                switch (error.code) {
                    case "file-invalid-type":
                        errors[fileIndex].errors.push("Dateityp ungültig.");
                        break;
                    case "file-too-large":
                        errors[fileIndex].errors.push("Datei zu groß.");
                        break;
                    default:
                        errors[fileIndex].errors.push("Unbekannter Fehler, erneut versuchen.");
                }
            });
        });

        // build component for notification
        const notificationBody = [];
        errors.forEach(error => {
            const errorComponent = error.errors.map((e) => {
                return <List.Item>{e}</List.Item>
            });
            notificationBody.push(
                <Stack spacing={0}>
                    <Text>{error.name}</Text>
                    <List size="sm">{errorComponent}</List>
                </Stack>
            )
        });

        // show notification
        showNotification({
            message: <Stack spacing={5}>
                <Text>Bitte prüfen Sie die ausgewählten Dateien.</Text>
                {notificationBody}
            </Stack>,
            color: 'red',
            icon: <X />
        });
    }

    /**
     * wrapper to download file from server
     * @param {object} file file to download
     */
    const downloadFile = async (file) => {
        // check if file from server, if not, just return
        if (file.fileSource !== FILE_SOURCE_INIT) {
            return;
        }

        // get file url
        const url = await getFileUrl(file.name);
        await downloadFileFromUrl(url, file.name);
    }

    /**
     * removes a file from the current files and marks them to be deleted later on if the user saves the form
     * @param {object} file file to remove
     */
    const removeFile = async (file) => {
        // if it was an init file, the file is stored on the server
        // which means we need to mark it to be deleted if the users saves later on
        if (file.fileSource === FILE_SOURCE_INIT) {
            filesToDeleteFromStorageRef.current.push(file.name);
        }

        // and we need to remove it from the local array for the current files as well
        var newFiles = [...filesRef.current];
        var deleteIndex = newFiles.findIndex(e => e.name === file.name);
        if (deleteIndex > -1) {
            newFiles.splice(deleteIndex, 1);
        }

        // set new files
        filesRef.current = newFiles;
        forceUpdate();
    }

    /**
     * renders a preview of the file
     * @param {object} file the file to show a preview for
     * @returns JSX
     */
    const getFilePreview = (file) => {
        if (file.fileSource === FILE_SOURCE_INIT && fileType === FILE_TYPE_IMAGE) {
            return (
                <Avatar
                    fileKey={file.name}
                />
            )
        }

        if (file.fileSource === FILE_SOURCE_SELECTED && fileType === FILE_TYPE_IMAGE) {
            return (
                <Avatar
                    file={file.file}
                />
            );
        }

        return (
            <Avatar
                placeholder={File}
            />
        );
    }

    return (
        <Input.Wrapper error={form.errors[path]}>
            <Input.Label required={withAsterisk}>{getLabel()}</Input.Label>
            {description &&
                <Input.Description mb={5}>{description}</Input.Description>
            }
            <InputBackground error={form.errors[path]}>
                <Flex
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                    gap="xs"
                    pb={(filesToRender && filesToRender.length > 0) ? "xs" : 0}
                >
                    {filesToRender && filesToRender.map(file => {
                        return (
                            <Card withBorder p="xs" key={file.name}>
                                <Stack spacing={5}>
                                    <Group spacing={5} position="right">
                                        {file.fileSource === FILE_SOURCE_INIT && <ActionIcon onClick={() => downloadFile(file)} variant="outline" color="blue" size={20}><Download /></ActionIcon>}
                                        <ActionIcon onClick={() => removeFile(file)} variant="outline" color="red" size={20}><Trash /></ActionIcon>
                                    </Group>
                                    <Paper withBorder radius={AVATAR_RADIUS}>
                                        {getFilePreview(file)}
                                    </Paper>
                                </Stack>
                            </Card>
                        )
                    })}
                </Flex>
                <Dropzone
                    onDrop={(files) => onAccept(files)}
                    onReject={(files) => onReject(files)}
                    accept={accept ? accept : null}
                    maxSize={maxSizeBytes}
                    maxFiles={multiple ? null : 1}
                    preventDropOnDocument={true}
                >
                    <Group>
                        <Dropzone.Accept>
                            <FileUpload size={50} color="green" />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <FileX size={50} color="red" />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <FileUpload size={50} />
                        </Dropzone.Idle>
                        <Stack spacing={4}>
                            <Text>{`${multiple ? "Dateien" : "Datei"} hier hin ziehen, oder klicken zum auszuwählen`}</Text>
                            {getDropzoneContent()}
                        </Stack>
                    </Group>
                </Dropzone>
            </InputBackground>
        </Input.Wrapper>
    );
}