import { Badge, Center, Text } from "@mantine/core";

/**
 * custom badge with auto height in case of text wrap
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeAutoHeight(props) {
    return (
        <Badge
            style={{
                height: "unset",
                padding: "5px",
                paddingRight: "10px",
                paddingLeft: "10px",
            }}
            {...props}
            leftSection={props.leftSection ? <Center>{props.leftSection}</Center> : null}
            rightSection={props.rightSection ? <Center>{props.rightSection}</Center> : null}
        >
            <Text style={{ whiteSpace: "break-spaces" }}>{props.children}</Text>
        </Badge>
    )
}