import React from 'react';
import { LoadingOverlay as MantineLoadingOverlay } from '@mantine/core';
import { useLoadingState } from '../helpers/GlobalLoadingState';

/**
 * Loading overlay implementation
 * @param {array} props The components props
 */
const LoadingOverlay = (props) => {
    const state = useLoadingState();

    return (
        <>
            <MantineLoadingOverlay
                visible={props.loading ? props.loading : state.loading}
                overlayBlur={2}
                loaderProps={{ size: 'xl', color: 'red', variant: 'bars' }}
                style={{
                    position: "fixed"
                }}
            />
            <div style={state.loading && state.hideChildren ? { display: "none" } : {}}>
                {props.children}
            </div>
        </>
    );
}

// Default export of the class
export default LoadingOverlay;