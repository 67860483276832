import { DataStore } from "aws-amplify";
import { Hub } from "aws-amplify";
import { Game } from "../models";
import { deleteFile } from "./Files";
import moment from "../helpers/Moment";
import { PREDICATE_GE, PREDICATE_LT } from "./Constants";
import { getFilterPredicates } from "./Predicates";
import * as Sentry from "@sentry/react";

/**
 * wrapper to wait for datastore to load
 */
export const waitForDataStoreLoad = async () => {
    return await new Promise((resolve) => {
        Hub.listen('datastore', async (hubData) => {
            const { event } = hubData.payload;

            if (event === 'ready') {
                resolve();
            }
        });

        DataStore.start();
    });
};

/**
 * wrapper to clear datastore
 * @returns promise
 */
export const clearDataStore = async () => {
    // clear datastore
    await DataStore.clear();

    // check if database was removed
    return await new Promise(async (resolve) => {
        var databaseExists = true;

        do {
            databaseExists = await checkDataStoreDbExists();
            setTimeout(() => { }, 500);
        } while (databaseExists);

        resolve();
    });
}

/**
 * check if datastore db exists
 * @returns boolean if db exists
 */
const checkDataStoreDbExists = async () => {
    // as indexed DB is a feature of chromium we need to check if it even exists
    if (window.indexedDB.databases) {
        // if it exists we can check if the database was removed
        const databases = await window.indexedDB.databases();
        return databases.map(db => db.name).includes("amplify-datastore");
    }
    else {
        // if it not exists, we can only wait for a bit longer, and assume that it was removed.
        setTimeout(() => { }, 500);
        return false;
    }
}

/**
 * helper function to delete a game and files
 * @param {string} id id of the game
 */
export const deleteGame = async (id) => {
    // fetch game
    const game = await DataStore.query(Game, id);
    if (!game) {
        throw new Error(`Game with id ${id} not found`);
    }

    // gather file names to delete
    const filesToDelete = [...game.cardImages] || [];
    if (game.cardBackgroundImage) {
        filesToDelete.push(game.cardBackgroundImage);
    }

    // create promises
    const promises = [];
    filesToDelete.forEach(file => {
        // if we did not skip before, we need to generate the video
        promises.push(new Promise(async (resolve) => {
            try {
                await deleteFile(file)
                resolve();
            }
            catch (e) {
                Sentry.captureException(e);
                resolve();
            }
        }));
    });

    // await promises
    await Promise.all(promises);

    // delete game
    await DataStore.delete(Game, id);
}

/**
* fetches the latest stats for a specific model
*/
export const fetchStats = async (type) => {
    // construct dates
    const dateToday = moment().format("YYYY-MM-DD");
    const dateYesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
    const dateOneWeek = moment().subtract(7, "days").format("YYYY-MM-DD");
    const dateTwoWeeks = moment().subtract(14, "days").format("YYYY-MM-DD");
    const dateOneMonth = moment().subtract(1, "months").format("YYYY-MM-DD");
    const dateTwoMonths = moment().subtract(2, "months").format("YYYY-MM-DD");
    const dateSixMonths = moment().subtract(6, "months").format("YYYY-MM-DD");
    const dateOneYear = moment().subtract(1, "years").format("YYYY-MM-DD");
    const dateTwoYears = moment().subtract(2, "years").format("YYYY-MM-DD");

    // construct array to fetch
    const statsToFetch = [
        { title: "Heute", date: dateToday, compare: dateYesterday },
        { title: "1 Woche", date: dateOneWeek, compare: dateTwoWeeks },
        { title: "1 Monat", date: dateOneMonth, compare: dateTwoMonths },
        { title: "6 Monate", date: dateSixMonths, compare: dateOneYear },
        { title: "1 Jahr", date: dateOneYear, compare: dateTwoYears },
    ];

    // fetch data
    const data = [];
    for (const stat of statsToFetch) {
        // create predicates
        const filterResult = [
            { key: "createdAt", value: stat.date, type: PREDICATE_GE },
        ];
        const compareResult = [
            { key: "createdAt", value: stat.compare, type: PREDICATE_GE },
            { key: "createdAt", value: stat.date, type: PREDICATE_LT },
        ]
        const filterPredicates = getFilterPredicates(filterResult);
        const comparePredicates = getFilterPredicates(compareResult);

        // get data
        const result = await DataStore.query(
            type,
            p => p.and(filterPredicates)
        );
        const compare = await DataStore.query(
            type,
            p => p.and(comparePredicates)
        );

        // get values
        var currentValue = result.length;
        var compareValue = compare.length;

        // calculate diff
        var diffValue = 0;
        if (!compareValue && currentValue) {
            // if the compare value is zero, but the current value is set, we do have a 100% increase
            diffValue = 1;
        }
        else if (compareValue === currentValue) {
            // if it had been the same we do have no increase
            diffValue = 0;
        }
        else {
            // otherwise we are going to calulate it
            diffValue = (currentValue - compareValue) / compareValue;
        }

        // add data
        data.push({ title: stat.title, count: currentValue, diff: diffValue });
    }

    // add summary data
    const all = await DataStore.query(
        type,
    );
    data.push({ title: "Gesamt", count: all.length });

    return data;
}